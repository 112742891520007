.wrapper {
    width: 550px;
}

.title {
    font-weight: 700;
    font-style: 18px;
    line-height: 23px;
    margin-bottom: 8px;
}

.btnWrapper {
    display: flex;
    gap: 8px;
    justify-content: end;
}