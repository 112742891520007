@import "../../../../assets/scss/helpers/variables";

.container {
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 30px;
  background-color: $white;
  padding: 16px 8px;
  box-shadow:
    4px 4px 8px 0px rgba(14, 56, 100, 0.08),
    1px 1px 1px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: 0.35s;

  &Active {
    top: 40px;
    visibility: visible;
    opacity: 1;
  }
}

.lists {
  margin: 12px 0;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.buttons {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}