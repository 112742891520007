@import "../../../assets/scss/helpers/variables";

.button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: auto;

    &>* {
        transition: color 0.35s;
    }

    svg {
        width: 20px;
        height: 20px;
        color: $buttons-primary-default;
    }

    .title {
        font-size: 14px;
        line-height: 24px;
        color: $text-body-dark;
    }

    &:hover {
        .title {
            color: $text-header-dark;
        }

        svg {
            color: $buttons-primary-hover;
        }
    }
}