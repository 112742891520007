@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.col {
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 233px;
  width: calc(233 / 1080 * 100%);
  max-height: 850px;
}

.colNotFixed {
  position: static;
}

.colInner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modelOpen {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.subTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.deleteBlock {
  margin-top: auto;
}