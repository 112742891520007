@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.card {
  @include clickableCard;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $buttons-primary-disabled;
  border-radius: 2px;
}

.mainInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.title {
  max-width: 200px;
  display: flex;
  flex-direction: column;

  @media (max-width: $sm) {
    max-width: 155px;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }
}

.secondaryInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 210px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 0;
    border-right: 0;
    background-color: transparent;
  }

  .description {
    white-space: normal;
    word-wrap: break-word;
    height: 47px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.characteristicsBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.characteristicsList {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.controlsPanned {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .controlsBlock,
  .downloadBlock {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.size {
  font-size: 14px;
  color: $text-header-dark;

  @media (max-width: $sm) {
    font-size: 12px;
  }
}

.secondary {
  font-size: 14px;
  color: $text-secondary-secondary;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $sm) {
    font-size: 12px;
  }
}