@import "../../../../assets/scss/helpers/variables";

.wrapper,
.spinnerWrapper {
  width: 968px;

  @media (max-width: $sm) {
    width: 760px;
  }
}

.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  margin-bottom: 4px;

  @media (max-width: $sm) {
    font-size: 26px;
    line-height: 33px;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 154px;
}

.error {
  margin: 50px auto 30px;
  width: 552px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;

  p {
    font-weight: 400;
    font-size: 20px;
    color: $text-secondary-secondary;

    @media (max-width: $md) {
      font-size: 18px;
    }

    @media (max-width: $sm) {
      font-size: 16px;
    }
  }
}