@import "../../../../../../assets/scss/helpers/variables";
@import "../../../../../../assets/scss/helpers/mixins";

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .helper {
    font-size: 20px;
    font-weight: 400;
    color: $text-secondary-secondary;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: $text-secondary-secondary;
    @media (max-width: $sm) {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .listContainer {
    @include shadows;
  }

  .alliances {
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border-left: 0;
      border-right: 0;
      background-color: #eeeef2;
    }
  }
  .alliance {
    padding-left: 5px;
    width: 100%;
    gap: 15px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    @include label;
    label {
      padding: 12px 0px 12px 5px;
      align-items: flex-start;
    }
    &Disabled {
      cursor: default;
      label {
        cursor: default;
      }
      p {
        color: $icon-primary-border-checkbox-radio-button;
      }

      &:hover {
        .hint {
          z-index: 1;
          opacity: 1;
          visibility: visible;
          transform: scale(1) translateY(-5px);
          @media (max-width: $sm) {
            transform: scale(1) translateY(10px);
          }
        }
      }
      position: relative;
      transition: 0.35s;

      .hint {
        @include large-hint-in-row;
      }
      &:last-of-type {
        .hint {
          top: -35%;
          @media (max-width: $sm) {
            top: -55%;
          }
          &::after {
            top: 100%;
            left: calc(10% - 6px);
            transform: rotate(180deg);
          }
        }
      }

      &:hover {
        [data-component="hidden"]:not(:checked) + [data-component="radio"]:after {
          transform: scale(0);
        }
      }
    }
    .col {
      gap: 4px;
      display: flex;
      flex-direction: column;
      .name {
        width: 440px;
        color: $text-header-dark;
        p {
          font-size: 16px;
          @media (max-width: $sm) {
            font-size: 14px;
          }
        }
      }
      .industry {
        color: $text-secondary-secondary;
        p {
          font-size: 14px;
          @media (max-width: $sm) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
