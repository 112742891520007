@import "../../../assets/scss/helpers/variables";

.container {
  border-bottom: 1px solid $line-primary;
  display: flex;
  gap: 50px;
  width: 100%;
}

.primary {
  gap: 52px;
}