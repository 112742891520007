@import '../../../assets/scss/helpers/variables';

.paramsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  color: $text-secondary-secondary;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: $text-header-dark;
}

.infoWrapper {
  margin: 24px 0;
}