@import '../../../../assets/scss/helpers/mixins';

.button {
  position: fixed;
  bottom: 36px;
  right: 36px;
  background-color: $buttons-primary-pressed;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.35s;

  &:hover {
    background-color: $buttons-primary-hover;

    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(-50%);
    }
  }

  .description {
    @include hint;
    top: 50%;
    right: calc(100% + 8px);
    transform-origin: right left;

    @media (max-width: $sm) {
      top: 50%;
      font-size: 12px;
    }

    &::after {
      display: inline-block;
      transform: rotate(90deg) translateY(-50%);
      top: calc(50% - 2px);
      right: -5.5px;
      left: auto;
    }
  }
}