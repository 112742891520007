@import "../../../../assets/scss/helpers/variables";

.container {
  width: 450px;
  display: flex;
  padding: 0 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    width: 70px;
    height: 60px;
    background: no-repeat center url(./assets/warning.svg);
  }
  .title {
    margin-top: 25px;
    font-size: 18px;
    color: $text-header-dark;
  }
  .subtitle {
    font-size: 14px;
    color: $text-secondary-secondary;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 25px;
  }
}
