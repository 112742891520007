@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";


.container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;

  svg {
    flex-shrink: 0;
    color: $buttons-primary-default;
  }

  &Primary svg {
    color: $icon-primary-border-checkbox-radio-button;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $text-secondary-secondary;
  }

  @media (max-width: $sm) {
    justify-content: center;

    &:hover {
      svg {
        color: $buttons-primary-hover;
      }

      .title {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(10px);
      }
    }

    .title {
      @include hint;
      z-index: 1;
      top: 100%;
      font-size: 12px;
      border-radius: 2px;

      @media (max-width: $sm) {
        top: 80%;
      }
    }
  }

}