@import "../../../../assets/scss/helpers/variables";

.container {
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin-top: 30px;
    font-size: 18px;
    color: $text-header-dark;
  }

  .info {
    font-size: 16px;
    color: $text-secondary-secondary;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 25px;
  }
}