@import "../../../assets/scss/helpers/variables";

.container {
    position: relative;
    align-self: start;
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    box-sizing: border-box;
    width: 100%;

    .icon {
        position: absolute;
        right: 0;
        bottom: 10px;
        cursor: pointer;
    }

    .input {
        top: 14px;
        left: 0;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $line-primary;
        outline: none;
        background: none;
        padding: 8px 0px;
        font-size: 16px;
        box-sizing: border-box;
        min-height: 38px;
        background-color: transparent;
        overflow: hidden;
        resize: none;

        @media (max-width: $sm) {
            font-size: 14px;
        }

        &:not(:placeholder-shown) ~ .label,
        &:focus ~ .label,
        &:valid ~ .label {
            transform: translateX(0px) translateY(-30px);
            font-size: 14px;
            cursor: default;
            @media (max-width: $sm) {
                font-size: 10px;
            }
        }

        &::placeholder {
            font-size: 14px;
            transition: 0.35s;

            @media (max-width: $sm) {
                font-size: 12px;
            }
        }

        &:focus {
            border-bottom: 1px solid $buttons-primary-default;
        }

        &:focus ~ span {
            display: inline;
        }

        &:focus::placeholder {
            color: transparent;
        }

        &:focus ~ .label,
        &:valid ~ .label {
            transform: translateX(0px) translateY(-30px);
            font-size: 12px;
            &Transparent {
                opacity: 0;
                visibility: hidden;
            }
            @media (max-width: $sm) {
                font-size: 10px;
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s;
            color: $white;
            font-size: 16px;
        }

        &NotValid {
            border-bottom: 1px solid $alert;

            &:focus {
                border-bottom: 1px solid $alert;
            }
        }

        &Disable {
            color: $text-secondary-secondary;
            pointer-events: none;
        }

        &HasIcon {
            padding-right: 50px;
        }
    }

    .label,
    .counter,
    .error {
        font-size: 14px;
        user-select: none;

        @media (max-width: $sm) {
            font-size: 12px;
        }
    }

    .label {
        position: absolute;
        top: 6px;
        transition: 0.6s;
        color: $text-secondary-secondary;
        cursor: text;
        &Required {
            &::after {
                content: "*";
                color: $alert;
                padding-left: 2px;
            }
        }
    }

    .counter {
        align-self: end;
        color: $text-secondary-secondary;
        font-weight: 400;
        display: none;
    }

    .error {
        color: $alert;
    }

    .counter,
    .error {
        position: absolute;
        bottom: -22px;
    }
}
