@import "../../../../assets/scss/helpers/variables";

.container {
    max-width: 380px;
    height: 64px;
    padding: 12px 30px 12px 16px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    border: 1px solid $line-primary;

    &Error {
        border: 1px solid $alert;
    }

    .info {
        display: grid;
        grid-template-areas: "icon name" "icon format";
        grid-template-columns: 40px auto;
        column-gap: 12px;
        opacity: 1;
        margin-right: auto;
        width: 100%;

        @media (max-width: $sm) {
            max-width: 390px;
        }

        &Disabled {
            opacity: 0.7;
        }

        .icon {
            grid-area: icon;
            width: 40px;
            height: 40px;
            background-color: $background-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .name {
            grid-area: name;
            max-width: 95%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $text-header-dark;
            font-weight: 400;
            font-size: 14px;
        }

        .format {
            grid-area: format;
            color: $text-secondary-secondary;
            font-weight: 400;
            font-size: 12px;
        }
    }

    .buttons {
        display: flex;
        gap: 27px;
        align-items: center;

        .delete {
            padding: 0;
            width: 20px;
            height: 20px;
            color: $icon-primary-counter;

            svg {
                color: currentColor;
            }
        }
    }
}