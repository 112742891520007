@import "../../../../../assets/scss/helpers/variables";
@import "../../../../../assets/scss/helpers/mixins";

.wrapper {
  position: relative;
  padding: 24px 24px 32px;
  background-color: $white;
  box-shadow: 0px 2px 4px rgba(14, 56, 100, 0.08);
  border-radius: 4px;
  border: 1px solid transparent;
  min-height: 410px;
  max-width: 552px;
  display: flex;
  flex-direction: column;
  transition: 0.35s;

  .btns {
    display: none;
  }

  &:hover {
    border: 1px solid $buttons-primary-hover;

    .btns {
      display: flex;
      gap: 16px;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}

.title {
  font-size: 14px;
  line-height: 18px;
  color: $text-secondary-secondary;
  margin-bottom: 31px;
}

.name {
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 16px;
}

.attachments {
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.descr {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
  height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;

  .descrText {
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 23px;
  }
}

.descrTitle,
.attachmentsTitle {
  font-size: 14px;
  line-height: 24px;
  color: $text-secondary-secondary;
  font-weight: normal;
}

.link {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
}

.linkText,
.attachmentsBtn {
  font-size: 16px;
  line-height: 23px;
  color: $buttons-primary-default;
}

.link {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 10%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: $dark-cerulean;
    border-top: 0;
    margin-left: -5px;
    margin-top: -5px;
    visibility: hidden;
    opacity: 0;
    transition: 0.35s;
    pointer-events: none;
  }

  &::after {
    content: attr(data-descr);
    white-space: nowrap;
    position: absolute;
    visibility: hidden;
    top: 29px;
    left: 0%;
    visibility: hidden;
    opacity: 0;
    transition: 0.35s;
    pointer-events: none;
  }

  &:active::before,
  &:hover:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 10%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: $dark-cerulean;
    border-top: 0;
    margin-left: -5px;
    margin-top: -5px;
    visibility: visible;
    opacity: 1;
  }

  &:active::after,
  &:hover:after {
    content: attr(data-descr);
    white-space: nowrap;
    position: absolute;
    display: block;
    top: 35px;
    left: -3%;
    background-color: $dark-cerulean;
    padding: 10px;
    color: $white;
    font-size: 16px;
    opacity: 1;
    visibility: visible;
    border-radius: 2px;
  }
}

.attachmentsBtn {
  cursor: pointer;
}

.attachmentsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attachmentsList {
  display: flex;
  gap: 8px;
  overflow-x: hidden;

  a {
    width: 162px;
  }
}