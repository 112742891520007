@import "../../../../../assets/scss/helpers/mixins";
.button {
    @include icon-favorites;
}

.activeFavoritesBtn {
    svg {
        transition: 0.35s;
        fill: $buttons-primary-default;
    }
    svg path {
        stroke: $buttons-primary-default;
    }
}
