@import "../../../../assets/scss/helpers/mixins";

.copy {
  display: block;
  width: 24px;
  height: 24px;
  color: $text-secondary-secondary;
  position: relative;
  transition: 0.35s;

  &:hover {
    color: $buttons-primary-hover;

    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }

  &:active {
    color: $buttons-primary-pressed;
  }
}

.description {
  @include hint;
}