@import "../../../assets/scss/helpers/variables";

.tag {
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 20px;
  padding: 4px 12px;
  display: inline-block;
  font-size: 14px;
  user-select: none;
  word-break: break-word;
}

@mixin outlined {
  color: $buttons-primary-default;
  border-color: $buttons-primary-default;
  cursor: pointer;
  background-color: transparent;
}

@mixin contained {
  color: $white;
  background-color: $buttons-primary-default;
  border-color: $buttons-primary-default;
  cursor: pointer;
}

.contained {
  @include contained;
}

.outlined {
  @include outlined;
}

.disabled {
  color: $buttons-primary-default;
  background-color: $background-info;
  border-color: $background-info;
}