.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  max-height: 120px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 0;
    border-right: 0;
    background-color: transparent;
  }
}