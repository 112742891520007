@import "../../../../assets/scss/helpers/variables";

.container {
  width: 1080px;
}

.info {
  margin-bottom: 28px;
  .title {
    font-weight: 600;
    font-size: 40px;
    font-size: 26px;
  }
  .description {
    margin-top: 12px;
    font-size: 12px;
    color: $gray-concrete;
    span {
      color: $alert;
    }
  }
}

.input {
  width: 100%;
  display: grid;
  grid-template-columns: auto 502px;
  gap: 44px;
}

.link {
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  z-index: 1;
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    color: $text-secondary-secondary;
  }
}

.textArea {
  margin: 44px 0;
}

.buttons {
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
