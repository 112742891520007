@import '../../../../../assets/scss/helpers/variables';
@import '../../../../../assets/scss/helpers/mixins';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.mainTitleWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mainTitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
}

.link {
    @include link;
}