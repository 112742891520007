@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.card {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid $line-primary;
  cursor: pointer;
  transition: 0.35s;
  list-style: none;

  &:hover {
    border: 1px solid $buttons-primary-default;
  }

  &Active {
    border: 1px solid $buttons-primary-hover;

    &:hover {
      border: 1px solid $buttons-primary-hover;
    }
  }

  &Single {
    border: 1px solid $buttons-primary-default;
    cursor: default;
  }

  &Disabled {
    cursor: default;
    position: relative;
    transition: 0.35s;

    .hint {
      @include large-hint-in-row;
    }

    &:hover {
      border: 1px solid $line-primary;

      .hint {
        z-index: 1;
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(8px);

        @media (max-width: $sm) {
          transform: scale(1) translateY(120px);
        }
      }
    }

    &:last-of-type {
      .hint {
        top: -35%;

        &::after {
          top: 100%;
          left: calc(10% - 6px);
          transform: rotate(180deg);
        }
      }
    }
  }
}

.clickableWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mainInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;
}

.nameWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: $text-header-dark;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.info {
  display: flex;
  gap: 4px;
  align-items: center;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: $gray-concrete;
  }

  .category {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.dot {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $line-primary;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $buttons-primary-disabled;
  border-radius: 2px;
}

.secondaryInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
}

.type {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $text-header-dark;
  text-overflow: ellipsis;
  overflow: hidden;
}

.weights {
  display: inline-flex;
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: $gray-concrete;

  .count {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    color: $text-header-dark;
  }
}

.badges {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  gap: 4px;
}