@import "../../../assets/scss/helpers/variables";

.wrapper {
  top: 0;
  position: absolute;
  right: 0;
  z-index: 0;
  width: 55px;
  background-color: transparent;
  height: 40px;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  transition: background-color 0.45s, border-bottom 0.35s, width 0.3s ease-in-out;

  @media (max-width: $sm) {
    height: 38px;
  }

  &Active {
    width: 100%;
    border-bottom: 1px solid $buttons-primary-default;
    background-color: inherit;
  }

  .close {
    width: 12px;
    height: 12px;
    align-self: center;
    margin-right: 3px;
    color: $text-secondary-secondary;
    transition: 0.35s;

    &:hover {
      color: $buttons-primary-default;
    }
  }

  .search {
    color: $text-secondary-secondary;
    transition: 0.35s;

    &:hover {
      color: $buttons-primary-default;
    }

    &Active {
      margin-right: 12px;
      color: $text-secondary-secondary;
      pointer-events: none;
      user-select: none;
    }
  }

  .container {
    width: 100%;
    align-self: flex-end;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    transition: 0.35s;
    opacity: 0;
    visibility: hidden;

    &Active {
      opacity: 1;
      visibility: visible;
    }

    .input {
      border: none;
      width: 100%;
      font-size: 16px;
      background-color: transparent;

      @media (max-width: $sm) {
        font-size: 14px;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        transition: background-color 600000s ease-in-out 0s !important;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        -webkit-text-fill-color: $text-header-dark !important;
      }
    }
  }
}