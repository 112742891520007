@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Raleway:wght@400;500;600;700&display=swap');
@import "../../../../../assets/scss/helpers/variables";

.wrapper {
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    border-radius: 60px;
    background-color: $background-grey;
    border: 1px solid $line-primary;
    font-family: 'IBM Plex Mono', monospace;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}