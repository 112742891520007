@import '../../../../assets/scss/helpers/variables';
@import '../../../../assets/scss/helpers/mixins';

.item {
  border: 1px solid $line-primary;
  background-color: $white;
  border-radius: 4px;
  line-height: 0;
  font-size: 0;
  overflow: hidden;

  &Styled {
    border-radius: 8px;
  }

  &Card {
    border: none;
    box-shadow: 0px 2px 4px rgba(14, 56, 100, 0.08);
  }
}

.withBackground {
  background-color: $background-grey;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 14px 14px 0px 14px;
  gap: 30px;
  cursor: pointer;
  transition: 0.35s;

  &Styled {
    padding: 20px 20px 0px 20px;
  }

  & .icon {
    color: $icon-primary-border-checkbox-radio-button;
    transition: 0.35s;

    &Positioned {
      position: absolute;
      right: 14px;
      top: 14px;
    }

    &Styled {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      border: 1px solid $line-primary;
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  .iconStyled.iconPositioned {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &.active {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.contentWrap {
  position: relative;
  overflow: hidden;
  padding: 0px 14px 14px 14px;
  transition-property: max-height;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

  &Styled {
    padding: 0px 20px 20px 20px;
  }
}