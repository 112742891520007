@import '../helpers/variables';
@import '../helpers/mixins';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;

  vertical-align: baseline;

  border: 0;
}

html {
  box-sizing: border-box;
  height: 100%; // http://tutorialzine.com/2016/03/quick-tip-the-best-way-to-make-sticky-footers/

  font-size: 62.5%; // 62.5% of the default of 16px, which equates to 10px

  background-color: transparent;

}

address {
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
  display: table;

  content: ' ';
}

.clearfix:after {
  clear: both;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
  word-break: normal;
  hyphens: none;

  overflow-wrap: break-word;
}

ul,
ol {
  list-style: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;

  column-count: 3;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;

  border: 0;
  border-top: 1px solid #eeeeee;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;

  font-size: 75%;
  line-height: 0;

  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;

  font: inherit;

  color: inherit;
}

button {
  overflow: visible;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

a {
  text-decoration: none;

  color: inherit;
  background-color: transparent;
}

a:focus,
a:hover {
  outline: none;
}

a:active {
  text-decoration: none;

  outline: none;
}
