@import '../../../../assets/scss/helpers/variables';

.wrapper {
  display: flex;
  align-items: center;
  gap: 11px;
}

.name {
  color: $buttons-primary-default;
  font-size: 26px;
  line-height: 38px;
  font-weight: 700;
}

.icons {
  display: flex;
  gap: 13px;
}

.editIcon,
.warnIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.editIcon {
  svg {
    width: 18px;
    height: 18px;
  }

  svg path {
    stroke: $buttons-primary-default;
  }
}

.warnIcon {
  position: relative;
  transition: 0.35s;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: $alert;
    border-top: 0;
    margin-left: -5px;
    margin-top: -5px;
    visibility: hidden;
    opacity: 0;
    transition: 0.35s;
    pointer-events: none;
  }

  &::after {
    content: attr(data-descr);
    white-space: nowrap;
    position: absolute;
    visibility: hidden;
    top: 29px;
    opacity: 0;
    transition: 0.35s;
    pointer-events: none;
  }

  /*hint*/
  &:active::before,
  &:hover:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: $alert;
    border-top: 0;
    margin-left: -5px;
    margin-top: -5px;
    visibility: visible;
    opacity: 0.8;
    z-index: 99;
  }

  &:active::after,
  &:hover:after {
    content: attr(data-descr);
    white-space: nowrap;
    position: absolute;
    display: block;
    top: 29px;
    background-color: $alert;
    padding: 10px;
    color: $white;
    font-size: 16px;
    opacity: 0.8;
    visibility: visible;
    border-radius: 2px;
    z-index: 99;
  }
}
