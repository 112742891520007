@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.wrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;

  &.withoutBorder {
    margin-top: 8px;
  }
}

.table {
  position: relative;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $line-secondary;
  background: $white;
  width: 100%;
}

.withoutBorder {
  padding: 0;
  border-radius: initial;
  border: 1px solid transparent;
}

.colored {
  background: $background-grey;
}

.columns {
  display: grid;
  border-bottom: 1px solid $line-secondary;
  font-size: 14px;
  line-height: 1.28;
  padding: 8px;
  margin-bottom: 12px;
  color: $text-secondary-secondary;

  @media (max-width: $sm) {
    font-size: 10px;
  }
}

.rows {
  li {
    display: grid;
    position: relative;
    padding: 0 8px;
    height: 76px;
    border-radius: 5px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $background-grey;
      transition: 0.35s;
    }

    @media (max-width: $sm) {
      height: 60px;
    }
  }

  & li:nth-child(even) {
    background-color: $background-grey;
  }

  &Colored {
    li {
      background-color: $background-grey;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $background-grey;
        transition: 0.35s;
      }
    }

    & li:nth-child(even) {
      background-color: $white;
    }
  }
}

.container {
  margin: 120px auto;
  width: 552px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
}

.search {
  margin: 80px auto;
  width: 552px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
}

.text {
  font-weight: 400;
  font-size: 20px;
  color: $text-secondary-secondary;

  @media (max-width: $md) {
    font-size: 18px;
  }

  @media (max-width: $sm) {
    font-size: 16px;
  }
}

.alliancesColumns {
  grid-template-columns: 35% 35.6% 15.2% 14.2%;

  li:last-child {
    justify-self: center;
  }
}

.favoriteAlliancesColumns {
  grid-template-columns: 30% 34.6% 13.2% 20.2% 2%;

  li:last-child {
    justify-self: center;
  }
}

.controlledAlliancesColumns {
  grid-template-columns: 22.5% 22.5% 30% 10% 10% 5%;

  li:last-child {
    justify-self: flex-end;
  }
}

.myAlliancesColumns {
  grid-template-columns: 25% 25% 30% 10% 10%;

  li:last-child {
    justify-self: center;
  }
}

.alliancesInvitationsColumns {
  grid-template-columns: 33% 35% 17% 15%;

  li:last-child {
    justify-self: center;
  }
}

.alliancesColumnsRequest {
  grid-template-columns: 30% 29.7% 11.5% 14% 14.8%;
}

.membersColumns {
  grid-template-columns: 20.8% 20.8% 20.8% 24% 13.6%;

  li:last-child {
    justify-self: flex-end;
  }
}

.membersColumnsInvites {
  grid-template-columns: 35% 45% 20%;
}

.allianceMembersRequestsColumns {
  grid-template-columns: 20.8% 20.8% 20.8% 16.5% 21.1%;

  li:last-child {
    justify-self: flex-end;
  }
}

.modelsColumns {
  grid-template-columns: 19.6% 18.7% 46% 15.7%;
}

.myModelsColumns {
  grid-template-columns: 33.8% 46.8% 15.3% 4.1%;
}

.addedModelsColumns {
  grid-template-columns: 27.8% 26.7% 27.8% 17.7%;
}

.addedWeightsColumns {
  grid-template-columns: 20% 30% 25% 35%;
}

.adminModelsColumns {
  grid-template-columns: 64% 31% 5%;

  li:last-child {
    justify-self: flex-end;
  }
}

.adminWeightColumns {
  grid-template-columns: 27% 43.6% 15.4% 14%;

  @media (max-width: $sm) {
    grid-template-columns: 32% 40.6% 11% 16.4%;
  }

  li:last-child {
    justify-self: center;
  }
}

.adminUsersColumns {
  grid-template-columns: repeat(4, 1fr) repeat(2, 0.9fr);
  gap: 10px;
}

.allianceModelsColumns {
  grid-template-columns: 29% 36% 17% 18%;

  li:last-child {
    justify-self: flex-end;
  }
}

.allianceModelsRequestsColumns {
  grid-template-columns: 36% 36% 14% 14%;

  li:last-child {
    justify-self: flex-end;
  }
}

.allianceWeightsRequestsColumns {
  grid-template-columns: 23.2% 23.2% 23.2% 14.9% 15.5%;

  li:last-child {
    justify-self: flex-end;
  }
}

.employeesColumns {
  grid-template-columns: 38% 36% 15% 11%;

  li:last-child {
    justify-self: center;
  }
}

.flEmployeesColumns {
  grid-template-columns: 35.5% 22.9% 28.6% 13%;
}

.flEmployeesControlledColumns {
  grid-template-columns: 28.4% 24.2% 23.4% 14.5% 10.5%;

  @media (max-width: $sm) {
    grid-template-columns: 22% 20% 29.4% 14.5% 14.5%;
  }
}

.adminMyAlliancesColumns,
.adminAllAlliancesColumns {
  li:last-child {
    justify-self: center;
  }

  grid-template-columns: 16.5% 16.5% 8.7% 19.2% 11.2% 14.45% 14.3%;

  @media (max-width: $sm) {
    grid-template-columns: 20% 20% 9% 23% 8% 8% 10.5%;
  }
}

.alliancesRows {
  li {
    grid-template-columns: 35% 35.6% 15.2% 14.2%;
  }
}

.favoriteAlliancesRows {
  li {
    grid-template-columns: 30% 34.6% 13.2% 20.2% 2%;
  }
}

.controlledAlliancesRows {
  li {
    grid-template-columns: 22.5% 22.5% 30% 10% 10% 5%;
  }
}

.myAlliancesRows {
  li {
    grid-template-columns: 25% 25% 30% 10% 10%;
  }
}

.alliancesRowsRequest {
  li {
    grid-template-columns: 30% 29.7% 11.5% 14% 14.8%;
  }
}

.alliancesInvitationsRows {
  li {
    grid-template-columns: 33% 35% 13% 19%;
  }
}

.membersRows {
  li {
    grid-template-columns: 20.8% 20.8% 20.8% 24% 13.6%;
  }
}

.membersRowsInvites {
  li {
    grid-template-columns: 35% 45% 20%;
  }
}

.allianceMembersRequestsRows {
  li {
    grid-template-columns: 20.8% 20.8% 20.8% 16.5% 21.1%;
  }
}

.modelsRows {
  li {
    grid-template-columns: 19.6% 18.7% 46% 15.7%;
  }
}

.myModelsRows {
  li {
    grid-template-columns: 33.8% 46.8% 15.3% 4.1%;
  }
}

.addedModelsRows {
  li {
    grid-template-columns: 27.8% 26.7% 27.8% 17.7%;
  }
}

.addedWeightsRows {
  li {
    grid-template-columns: 20% 30% 37% 23%;
  }
}

.adminModelsRows {
  li {
    grid-template-columns: 64% 31% 5%;
  }
}

.adminWeightRows {
  li {
    grid-template-columns: 27% 43.6% 15.4% 14%;
  }

  @media (max-width: $sm) {
    li {
      grid-template-columns: 32% 40.6% 11% 16.4%;
    }
  }
}

.adminUsersRows {
  li {
    grid-template-columns: repeat(4, 1fr) repeat(2, 0.9fr);
    gap: 10px;

    @media (max-width: $sm) {
      height: auto;
    }
  }
}

.allianceModelsRows {
  li {
    grid-template-columns: 29% 36% 17% 18%;
  }
}

.allianceModelsRequestsRows {
  li {
    grid-template-columns: 36% 36% 14% 14%;
  }
}

.allianceWeightsRequestsRows {
  li {
    grid-template-columns: 23.2% 23.2% 23.2% 14.9% 15.5%;
  }
}

.employeesRows {
  li {
    grid-template-columns: 38% 36% 15% 11%;
  }
}

.flEmployeesRows {
  li {
    height: 48px;
    grid-template-columns: 35.5% 22.9% 28.6% 13%;
  }
}

.flEmployeesControlledRows {
  li {
    height: 48px;
    grid-template-columns: 28.4% 24.2% 23.4% 14.5% 10.5%;

    @media (max-width: $sm) {
      grid-template-columns: 22% 20% 29.4% 14.5% 14.5%;
    }
  }

}

.adminAllAlliancesRows,
.adminMyAlliancesRows {

  li {
    height: 76px;
    grid-template-columns: 16.5% 16.5% 8.7% 19.2% 11.2% 14.45% 14.3%;
  }

  @media (max-width: $sm) {
    li {
      grid-template-columns: 20% 20% 9% 23% 8% 8% 10.5%;
    }
  }
}