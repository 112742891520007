@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.container {
  @include card;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 18px;
  @media (max-width: $sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.gridWeights {
  display: grid;
  grid-template-columns: repeat(2, 530px);
  gap: 20px;
  @media (max-width: $sm) {
    grid-template-columns: repeat(2, 385px);
  }
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.helper {
  margin: 120px auto;
  width: 552px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
}

.text {
  font-weight: 400;
  font-size: 20px;
  color: $text-secondary-secondary;

  @media (max-width: $md) {
    font-size: 18px;
  }

  @media (max-width: $sm) {
    font-size: 16px;
  }
}
