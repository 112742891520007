@import "../../../assets/scss/helpers/variables";

.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  svg {
    width: 15px;
    height: 25px;
  }
  @media (max-width: $sm) {
    svg {
      width: 10px;
      height: 15px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    color: $text-secondary-secondary;
    .date {
      font-weight: 400;
    }
    @media (max-width: $sm) {
      font-size: 12px;
    }
  }
}
