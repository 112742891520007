@import "../../../assets/scss/helpers/variables";

.container {
  margin-top: 24px;
  .title {
    color: $text-secondary-secondary;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 26px;
    &Required {
      &::after {
        content: "*";
        color: $alert;
        padding-left: 2px;
      }
    }
    &Bold {
      font-size: 18px;
      font-weight: 700;
      line-height: 23px;
      color: $text-header-dark;
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
}
