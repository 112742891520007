@import "../../../../../assets/scss/helpers/mixins";
.button {
  @include icon-button-with-hint;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  @media (max-width: $sm) {
    width: 24px;
    height: 24px;
  }
}

.info {
  color: $buttons-primary-default;
  border: 1px solid $buttons-primary-default;
}
