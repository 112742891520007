@import '../../../../../assets/scss/helpers/variables';

.container {
  position: relative;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: $background-grey;

  .metric {
    width: 837px;
    height: 307px;
  }

  &::after,
  &::before {
    position: absolute;
    bottom: 18px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: $text-secondary-secondary;
  }

  &::after {
    content: 'Начало раунда';
    left: 85px;
  }

  &::before {
    content: 'Конец раунда';
    right: 12px;
  }
}