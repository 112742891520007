@import '../../../assets/scss/helpers/variables';
@import '../../../assets/scss/helpers/mixins';

.wrapper {
  border-radius: 8px;
  border: 1px solid $line-primary;
  background: $rows-light;
  padding: 20px;

  @media (max-width: $sm) {
    gap: 15px;
    padding: 10px 20px;
  }
}

.wrapperFlex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.wrapperGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 237px;
}

.infoBlock,
.textPrimary {
  @include one-line-text-overflow;
}

.title,
.textSecondary {
  font-size: 14px;
  line-height: 17px;
}

.title {
  color: $text-secondary-secondary;
  font-weight: 400;

  @media (max-width: $sm) {
    font-size: 10px;
    line-height: 16px;
  }
}

.textPrimary {
  color: $text-header-dark;
  font-size: 16px;
  line-height: 23px;

  @media (max-width: $sm) {
    font-size: 14px;
    line-height: 18px;
  }
}

.textSecondary {
  color: $text-secondary-secondary;

  @media (max-width: $sm) {
    font-size: 12px;
    line-height: 20px;
  }
}

.email {
  @include email;
  text-decoration: none;
  font-size: 16px;
  color: $buttons-primary-default;

  @media (max-width: $sm) {
    font-size: 14px;
    line-height: 18px;
  }
}