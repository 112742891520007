@import "../../../../assets/scss/helpers/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 450px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $text-header-dark;
}

.title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 8px;
  color: $text-body-dark;
}

.info {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: $text-body-dark;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
