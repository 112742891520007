@import '../../../../../assets/scss/helpers/variables';
@import '../../../../../assets/scss/helpers/mixins';

.invalidStateWrapper {
    padding: 8px;
    background-color: $alert-background;
    border-radius: 8px;
    margin-top: 20px;
    border: 1px solid $alert;

    div {
        background-color: transparent;
        display: flex;
        justify-content: start;
        padding: 0;
        border: none;
    }
}

.invalidStateContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 8px;

    .invalidStateText {
        font-size: 12px;
        line-height: 20px;
    }
}

.link {
    @include link;
    font-size: inherit;
    line-height: inherit;
}