@import "../../../../assets/scss/helpers/variables";

.helper {
    position: relative;

    .button {
        border-radius: 50%;
        background-color: $background-secondary;
        color: $buttons-primary-default;
        padding: 0px 6px;
        font-size: 12px;
        transition: 0.35s;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $buttons-primary-hover;
            color: $white;
        }

        &:focus {
            background-color: $buttons-primary-pressed;
            color: $white;
        }

        &:focus~.popup {
            opacity: 1;
            visibility: visible;
        }
    }

    .popup {
        position: absolute;
        top: 0;
        left: 25px;
        z-index: 2;
        width: 350px;
        border-radius: 2px;
        padding: 12px;
        background-color: $background-grey;
        box-shadow: 0px 2px 4px rgba(14, 56, 100, 0.08);
        opacity: 0;
        visibility: hidden;
        transition: 0.35s;
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        line-height: 20px;

        p {
            font-size: 14px;
            font-style: normal;
            color: $text-secondary-secondary;
        }

        span {
            font-size: 14px;
            font-weight: 700;
            color: $text-header-dark;
        }
    }
}