@import '../../../../../assets/scss/helpers/variables';

.wrapper {
  width: 552px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin-bottom: 12px;
  margin-top: 22px;
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
}

.text {
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 26px;
  color: $text-secondary-secondary;
  text-align: center;
}