@import "../../../../../assets/scss/helpers/variables";
@import "../../../../../assets/scss/helpers/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;

  svg {
    width: 24px;
    height: 24px;
    transition: 0.35s;
  }


  &:hover {
    color: $buttons-primary-hover;

    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }

}

.description {
  @include hint;
  top: calc(100%);

  @media (max-width: $sm) {
    top: calc(100%);
  }
}