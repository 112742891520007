.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 750px;

  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
}

.metrics {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}