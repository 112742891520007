@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.card {
  @include clickableCard;
}

.wrapper {
  display: flex;
  justify-content: space-between;

  .container {
    width: 70%;
    display: flex;
    flex-direction: row;
    gap: 12px;

    @media (max-width: $sm) {
      width: 65%;
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: $text-header-dark;
        text-overflow: ellipsis;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: $text-secondary-secondary;
      }
    }
  }
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: $background-secondary;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 75%;
}

.type {
  font-size: 14px;
  font-weight: 400;
  color: $text-header-dark;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weights {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 12px;

  span {
    font-weight: 700;
    color: $text-header-dark;
  }

  p {
    font-weight: 400;
    color: $text-body-dark;
  }
}

.learning {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconsWrap {
  position: relative;
  align-self: flex-end;
}

.date {
  font-size: 12px;
  font-weight: 400;
  color: $text-secondary-secondary;
}