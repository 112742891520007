@import "../../../assets/scss/helpers/variables";

.container {
  position: relative;
  padding: 20px;
  width: 406px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 2px;
  background-color: $white;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    list-style-type: none;
  }

  li,
  p,
  ul {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-body-dark;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $text-header-dark;
}

.navBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: $text-secondary-secondary;
}

.closeBtn {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  color: $text-header-dark;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  border-radius: 2px;

  &:hover {
    background-color: $buttons-primary-hover;
    color: $white;
  }

  &:active {
    background-color: $buttons-primary-pressed;
    color: $white;
  }
}