@import "../../../../../assets/scss/helpers/variables";

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.model {
  max-height: calc(70vh - 100px);
  li {
    & > div:last-of-type {
      ul:last-of-type {
        overflow-y: auto;
        max-height: calc(70vh - 313px);
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border-left: 0;
          border-right: 0;
          background-color: #eeeef2;
        }
      }
    }
  }
  @media (max-width: $sm) {
    max-height: calc(80vh - 160px);
    li {
      & > div:last-of-type {
        ul:last-of-type {
          max-height: calc(80vh - 349px);
        }
      }
    }
  }
}
.buttons {
  margin-left: auto;
  display: flex;
  gap: 8px;
}
