@import '../../../assets/scss/helpers/variables';

.wrapper,
.spinnerWrapper {
    width: 60%;
    display: flex;
}

.wrapper {
    background-color: $background-grey;
    border-radius: 8px;
    padding: 20px;
    flex-direction: column;
}

.spinnerWrapper {
    justify-content: center;
}

.titleWrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
        width: 24px;
        height: 24px;
        color: $success;
    }
}

.title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
}

.subtitle {
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
    margin-bottom: 20px;
}

.text {
    margin-top: 16px;
    font-size: 16px;
    line-height: 23px;
    word-wrap: normal;
    word-break: break-all;
    text-wrap: pretty;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    animation: fadeIn 0.5s;

    button {
        align-self: start;
    }
}