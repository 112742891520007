@import "../../../../assets/scss/helpers/variables";

.button {
  padding: 8px 12px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  background-color: $white;
  border: 1px solid $line-primary;
  border-radius: 4px;
  transition: 0.35s;

  p {
    font-size: 14px;
    font-weight: 400;
    color: $gray-concrete;
  }

  &Applied {
    border: 1px solid $buttons-primary-default;

    p {
      font-weight: 600;
    }
  }

  &Active {
    border: 1px solid $buttons-primary-pressed;
  }

  &:hover {
    border: 1px solid $buttons-primary-hover;
  }
}

.count {
  font-size: 14px;
  font-weight: 700;
  color: $buttons-primary-default;
}

.icon {
  margin-left: 3px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid $icon-primary-counter;
  transition: 0.35s;

  &Applied {
    border-top: 6px solid $gray-concrete;

    p {
      font-weight: 600;
    }
  }

  &Active {
    transform: rotate(180deg);
  }
}