@import '../../../../assets/scss/helpers/mixins';

.wrapper {
    width: 1048px;
}

.title {
    font-size: 26px;
    line-height: 33px;
    font-weight: 600;
    margin-bottom: 28px;
}

.infoBlockWrapper {
    margin-bottom: 44px;
    margin-top: 25px;
}

.paramsWrapper {
    margin-top: 25px;
    padding-bottom: 30px;
    overflow: clip;
}

.inputsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.form {
    max-height: 60vh;
    min-height: 150px;
    @include scroll;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 28px;
}