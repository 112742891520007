@import '../../../../assets/scss/helpers/variables';

.wrapper {
    width: 1048px;
}

.title {
    font-size: 26px;
    line-height: 33px;
    font-weight: 600;
    margin-bottom: 12px;
}

.formSubtitle {
    color: $text-secondary-secondary;
    font-size: 12px;
    line-height: 24px;

    span {
        color: $alert;
    }
}

.inputsWrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 28px 0;
}

.btnsWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}