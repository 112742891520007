.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 28px;
  overflow: hidden;
}

.buttons {
  margin-left: auto;
  margin-top: auto;
  display: flex;
  gap: 8px;
}
