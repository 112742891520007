@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.title {
    font-size: 26px;
    font-weight: 600;
}

.text {
    margin: 40px 0 28px;
    font-size: 16px;
    width: 472px;
    @include text-wrapped;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    grid-template-rows: 1fr 0.5fr;
    row-gap: 24px;
    width: 472px;
}

.personalInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.gridTitle {
    font-size: 14px;
    color: $text-secondary-secondary;
}

.textPrimary {
    font-size: 14px;
    color: $text-header-dark;
}

.textSecondary {
    font-size: 14px;
    color: $text-secondary-secondary;
}

.textDecorated {
    text-decoration: underline;
}