@import "../../../../../assets/scss/helpers/variables";
@import "../../../../../assets/scss/helpers/mixins";

.container {
  border-radius: 10px;
  padding: 8px;
  background-color: rgba(255, 58, 64, 0.08);
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: 8px;
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
  }
  color: $alert;
  svg {
    width: 10px;
    height: 10px;
  }
}

.reports {
  padding-left: 10px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.report {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: -7.2px;
    top: 7.2px;
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $text-header-dark;
  }
}

.link {
  @include link;
  display: block;
  width: 180px;
  font-size: 12px;
}
