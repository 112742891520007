// Fonts
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");
$font-family-base: "Nunito Sans",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "Helvetica Neue",
  Arial,
  sans-serif;
$font-family-base: "Nunito Sans",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "Helvetica Neue",
  Arial,
  sans-serif;
$font-size-base: 22px;
$line-height-base: 1.455;
// Base colors
$background-cards-dark: #031630;
$background-grey: #f7f7f8;
$text-header-dark-card: #f7f7f8;
$rows-light: #fbfcfe;
$text-header-dark-inverse: #ffffff;
$background-secondary: #ecf3fe;
$background-info: #ecf3fe;
$background-modal-view: rgba(21, 23, 25, 0.20);
$background-green: #ddfde3;
$text-header-dark: #151719;
$text-hover: #0d70f2;
$text-primary-black: #151719;
$text-primary-inverse: #ffffff;
$text-secondary-secondary: #697381;
$text-green-default-states-user: #07831c;
$text-body-dark: #3f454d;
$buttons-primary-default: #0d70f2;
$buttons-primary-hover: #0a5ac2;
$buttons-primary-pressed: #084391;
$buttons-primary-disabled: #ebedef;
$button-secondary: #0d70f2;
$line-primary: #c1c6cd;
$line-secondary: #e0e3e6;
$white: #ffffff;
$gray-concrete: #697381;
$dark-cerulean: #031630; // Tooltip,notification 
$notification-pressed: #052d61;
$main-bg: #0d0e16;
$success: #07831c;
$warning-main: #875203;
$warning-icon: #faad39;
$warning-background: #feefd7;
$icon-primary-primary: #0d70f2;
$icons-primary: #0d70f2;
$icon-primary-counter: #697381;
$icon-primary-disabled: #a4abb4;
$icon-secondary-secondary: #0d70f2;
$icon-primary-border-checkbox-radio-button: #697381;
$additional-tech-icons: #0d70f2;
$alert: #e90b11;
$alert-hover: #ba090e;
$alert-pressed: #8c070a;
$alert-background: #fef0f1;
// Breakpoints
$lg: 1920px;
$md: 1687px;
$sm: 1435px;
//
$xxl: 1199px;
$xxxl: 1439px;
$xxxxl: 1779px;
// Grid
$gutter: 30px;
// Animation
$base-duration: 0.3s;
// Header
$header-height-desktop: 80px;
$header-height-mobile: 55px;