@import "../../../assets/scss/helpers/variables";

.container {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $buttons-primary-default;
}