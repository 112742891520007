@import "../../../assets/scss/helpers/variables";

.container {
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $background-info;
  border: 1px solid $buttons-primary-default;
  border-radius: 4px;
  gap: 20px;

  &Secondary {
    height: auto;
    padding: 10px 12px;
  }

  &Warning {
    background-color: $alert-background;
    border: 1px solid $alert;
  }

  &Bold {
    background-color: $background-secondary;

    .text {
      font-weight: 700;
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;

    .icon {
      display: block;
      width: 24px;
      height: 24px;
    }

    .text {
      font-size: 14px;
      line-height: 18px;

      @media (max-width: $sm) {
        font-size: 12px;
      }
    }
  }
}