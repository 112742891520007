@import "../../../assets/scss/helpers/variables";

.buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
}

.confirm {
  width: 100px;
  display: flex;
  color: $text-secondary-secondary;
  font-size: 14px;
  gap: 12px;
  align-items: center;
}

.reject {
  width: 100px;
  display: flex;
  color: $text-secondary-secondary;
  font-size: 14px;
  gap: 13px;
  align-items: center;
}
