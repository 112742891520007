@import '../../../../../../../assets/scss/helpers/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title,
.subtitle {
    font-size: 12px;
    line-height: 20px;
}

.title {
    color: #E90B11;
}

.subtitle {
    color: $text-secondary-secondary;
}

.titleWrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
        width: 19px;
        height: 17px;
    }
}

.btnWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}