@import "../../../assets/scss/helpers/mixins";

.wrapper {
  display: flex;
  line-height: 20px;

  h3 {
    font-size: 12px;
    font-weight: normal;
    color: $text-secondary-secondary;
  }

  p {
    font-size: 16px;
    word-wrap: break-word;
    color: $text-header-dark;
  }

  .text {
    color: $text-secondary-secondary;
  }
}

.column {
  flex-direction: column;
  gap: 12px;

  @media (max-width: $sm) {
    gap: 8px;
  }
}

.row {
  flex-direction: row;
  align-items: center;
  gap: 8px;

  h3 {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    color: $text-secondary-secondary;

    @media (max-width: $sm) {
      font-size: 14px;
    }
  }

  @media (max-width: $sm) {
    gap: 4px;
  }
}

.smallGaps {
  gap: 4px;
}