@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.container {
  position: relative;
  margin-left: auto;

  .menu {
    cursor: pointer;
    height: 25px;
    width: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .dot {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $icon-primary-counter;
      transition: 0.35s;
    }

    &:hover {
      .dot {
        background-color: $buttons-primary-hover;
      }
    }

    &Active {
      .dot {
        background-color: $buttons-primary-pressed;
      }
    }
  }

  .buttons {
    position: absolute;
    top: 0;
    right: -20px;
    box-shadow: 0px 2px 5px 0px $background-grey;
    transition: 0.35s;
    opacity: 0;
    transform: scaleX(0);
    visibility: hidden;
    z-index: 3;

    &Active {
      position: absolute;
      top: 5px;
      box-shadow: 0px 2px 4px 0px rgba(14, 56, 100, 0.08);
      right: 20px;
      opacity: 1;
      visibility: visible;
      transform: scaleX(1);
      z-index: 3;
    }

    .button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      padding: 8px 2px;
      font-size: 14px;
      background-color: $white;
      transition: 0.35s;

      &:hover {
        background-color: $background-grey;
      }

      &Delete {
        color: $alert
      }

      &Delete:hover {
        background-color: $alert;
        color: $white;
      }

      &:disabled {
        color: $line-primary;
        background-color: $white;
      }

      &:disabled:hover {
        .description {
          opacity: 1;
          visibility: visible;
          transform: scale(1) translateY(10px);
        }
      }
    }
  }
}

.description {
  @include hint;
  z-index: 1;
}