@import "../../../assets/scss/helpers/variables";

.container {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 2px 12px;
  border-radius: 20px;
  border: 1px solid $line-primary;
  color: $text-header-dark;

  &Selected {
    background-color: $line-primary;
    color: $text-header-dark;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: normal;
    word-break: break-all;
    text-wrap: pretty;

    span {
      font-weight: 700;
      line-height: 18px;
    }
  }
}

.cursor {
  cursor: pointer;
}

.blue {
  border-color: #137cf0;
  color: #137cf0;

  &Selected {
    color: white;
    background-color: #137cf0;
  }
}

.pink {
  border-color: #f013b2;
  color: #f013b2;

  &Selected {
    color: white;
    background-color: #f013b2;
  }
}

.red {
  border-color: #f03b13;
  color: #f03b13;

  &Selected {
    color: white;
    background-color: #f03b13;
  }
}

.teal {
  border-color: #02a5b1;
  color: #02a5b1;

  &Selected {
    color: white;
    background-color: #02a5b1;
  }
}

.green {
  border-color: #08b54a;
  color: #08b54a;

  &Selected {
    color: white;
    background-color: #08b54a;
  }
}

.orange {
  border-color: #f59e0b;
  color: #f59e0b;

  &Selected {
    color: white;
    background-color: #f59e0b;
  }
}

.violet {
  border-color: #a148f4;
  color: #a148f4;

  &Selected {
    color: white;
    background-color: #a148f4;
  }
}

.btnsContainer {
  position: relative;
  display: flex;
  gap: 8px;

  button:disabled {
    background-color: transparent;
  }
}

.disabled {
  opacity: 0.5;
}