@import "../../../../../assets/scss/helpers/variables";

.list {
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: $background-grey;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

.name {
  font-size: 14px;
  font-weight: 400;
  color: $text-header-dark;
  min-width: 250px;
  width: max-content;
  padding-right: 5px;
}

.count {
  margin-left: 15px;
  font-size: 12px;
  font-weight: 700;
  color: $line-primary;
  transition: 0.35s;
  color: $icon-primary-counter;
}