@import '../../../../../assets/scss/helpers/mixins';

.button {
  @include icon-button-with-hint;
}

.decline {
  border: 1px solid $line-primary;
  color: $icon-primary-counter;
}

.smallButton {
  width: 20px;
  height: 20px;
  border: transparent;
  color: $icon-primary-counter;

  svg path {
    stroke: $icon-primary-counter;
  }

  &:hover,
  &:active {
    border: 1px solid transparent;
    background-color: transparent;
    z-index: 1;
    color: $icon-primary-primary;

    svg path {
      stroke: $icon-primary-primary;
    }
  }

  &::after {
    content: attr(data-descr);
    white-space: nowrap;
    position: absolute;
    visibility: hidden;
    top: 23px;
    opacity: 0;
    transition: 0.35s;
    pointer-events: none;
  }

  &:active::after,
  &:hover:after {
    top: 28px;
  }
}