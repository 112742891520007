@import "../../../../assets/scss/helpers/variables";
.popUp {
    background-color: $background-grey;
    border-radius: 2px;
    padding: 15px 19px;
    line-height: 128%;
    color: $text-header-dark;
    width: 400px;
    li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        .icon {
            margin-right: 11px;
        }
    }
    li:last-child {
        margin-bottom: 0;
    }
}

.liActive {
    color: $text-secondary-secondary;
}

.hintIcon {
    color: $buttons-primary-default;
    background-color: $background-secondary;
    border-radius: 50%;
    padding: 0px 6px;
    font-size: 12px;
    margin-left: 6px;
    cursor: pointer;
    &:hover {
        color: $white;
        background-color: $icons-primary;
    }
}

.hintWrapper {
    position: relative;
}

.hintList {
    position: absolute;
    display: inline-block;
    top: 1px;
    left: 29px;
    z-index: 5;
}
