@import '../../../assets/scss/helpers/variables';

.wrapper {
    background-color: $background-grey;
    border-radius: 12px;
    padding: 32px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 119px;
}

.title {
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
    margin-bottom: 16px;
}

.text {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 20px;
}

.btnWrapper {
    display: inline-flex;

    a {
        justify-self: end;
        border: 1px solid $line-primary;
        padding: 11px 12px;
        border-radius: 2px;
        color: $buttons-primary-default;
        font-size: 14px;
        line-height: 18px;
    }

    a:hover {
        color: $buttons-primary-hover;
    }
}