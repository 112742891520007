@import "../../../../assets/scss/helpers/variables";


.form {
  border: 1px solid $line-primary;
  border-radius: 8px;
  padding: 20px;
}

.inputsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.label {
  font-size: 14px;
  line-height: 24px;
  color: $text-secondary-secondary;
  font-family: $font-family-base;
  padding-bottom: 8px;
}

.buttonsWrapper {
  display: flex;
  gap: 24px;
  justify-content: end;
  margin-top: 32px;
}