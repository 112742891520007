@import '../../../../assets/scss/helpers/variables';
@import '../../../../assets/scss/helpers/mixins';

.wrapper {
  width: 1128px;

  @media (max-width: $md) {
    width: 65vw;
  }
}

.wrapperFullWidth {
  width: 100%;
}

.title {
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  margin-bottom: 28px;
}

.content {
  max-height: 70vh;
  @include scroll;
}

.contentInner {
  margin-bottom: 1px;
  border: 1px solid $line-primary;
  border-radius: 8px;
}

.contentHead {
  padding: 24px 24px 20px;
  border-bottom: 1px solid $line-primary;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentBody {
  padding: 24px 25px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.rightSide {
  display: flex;
  gap: 20px;
}

.rightSide {
  align-items: center;
}

.name {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .nameText {
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
  }

  .nameTitle {
    font-size: 12px;
    line-height: 20px;
    color: $text-secondary-secondary;
  }
}

.date {
  font-size: 14px;
  line-height: 18px;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;
}

.extra {
  display: grid;
  grid-template-columns: 2fr 1fr;

  .description {
    padding-right: 32px;
    border-right: 1px solid $buttons-primary-disabled;
  }

  .fileWrap {
    padding-left: 32px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .file {
      padding-left: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.metricsTitle {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  margin-bottom: 16px;
}

.metricsTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}