@import "../../../assets/scss/helpers/variables";

.wrapper {
  border: 1px solid transparent;
  position: relative;

  &Disable {
    pointer-events: none;
  }

  .options {
    margin-top: 5px;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    max-height: 230px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: $white;
    z-index: 2;
    overflow-y: scroll;
    box-shadow: 0px 2px 5px 0px $background-grey;
    visibility: hidden;
    opacity: 0;
    transition: 0.35s;
    user-select: none;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      border-left: 0;
      border-right: 0;
      background-color: $line-secondary;
    }

    .option {
      background-color: $white;
      font-size: 16px;
      width: 100%;
      height: 45px;
      padding: 10px;

      @media (max-width: $sm) {
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
        background-color: $background-grey;
      }
    }
  }

  .container {
    position: relative;
    height: 48px;
    margin-bottom: 4px;
    margin-top: 8px;
    box-sizing: border-box;
    cursor: pointer;

    .input {
      position: absolute;
      top: 14px;
      left: 0;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $line-primary;
      outline: none;
      background: none;
      padding: 8px 34px 8px 0px;
      font-size: 16px;
      box-sizing: border-box;
      height: 38px;
      background-color: transparent;
      cursor: pointer;
      user-select: none;

      &::placeholder {
        opacity: 0;
      }

      @media (max-width: $sm) {
        font-size: 14px;
      }

      &:focus {
        border-bottom: 1px solid $buttons-primary-default;

        &::placeholder {
          opacity: 1;
          font-size: 12px;
        }
      }

      &:focus ~ .btn {
        display: none;
      }

      &:focus ~ .button {
        position: absolute;
        right: 0;
        top: 25px;
        width: 16px;
        height: 9px;
        display: block;
        background: no-repeat center/contain url(./OptionsButton/OptionsButton.svg);
        transform: rotate(180deg);
      }

      &:focus ~ .options {
        // margin-top: 5px;
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        max-height: 230px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        background-color: $white;
        z-index: 2;
        overflow-y: scroll;
        box-shadow: 0px 2px 5px 0px $background-grey;
        visibility: visible;
        opacity: 1;
      }

      &:not(:placeholder-shown) ~ .label,
      &:focus ~ .label,
      &:valid ~ .label {
        transform: translateX(0px) translateY(-30px);
        font-size: 12px;

        @media (max-width: $sm) {
          font-size: 10px;
        }
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
        color: $white;
        font-size: 16px;
      }

      &Disable {
        color: $text-secondary-secondary;
      }

      &NotValid {
        border-bottom: 1px solid $alert;

        &:focus {
          border-bottom: 1px solid $alert;
        }
      }
    }

    .button {
      display: none;
    }

    .label {
      position: absolute;
      top: 20px;
      transition: 0.6s;
      font-size: 14px;
      color: $text-secondary-secondary;
      cursor: pointer;
      user-select: none;

      &Required {
        &::after {
          content: "*";
          color: $alert;
          padding-left: 2px;
        }
      }

      @media (max-width: $sm) {
        font-size: 12px;
      }
    }

    .btn {
      content: "";
      position: absolute;
      right: 0;
      top: 25px;
      width: 16px;
      height: 9px;
      display: block;
      background: no-repeat center/contain url(./OptionsButton/OptionsButton.svg);
      cursor: pointer;
    }
  }

  .error {
    padding-top: 4px;
    color: $alert;
    height: 19px;
    font-size: 14px;

    @media (max-width: $sm) {
      font-size: 12px;
    }
  }
}
