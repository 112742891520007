@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 2px;
    transition: 0.35s;
    flex-shrink: 0;

    &:hover {
        .description {
            opacity: 1;
            visibility: visible;
            transform: scale(1) translateY(10px);
        }
    }

    .description {
        @include hint;
        top: 100%;
        border-radius: 2px;

        @media (max-width: $sm) {
            top: 100%;
            font-size: 12px;
        }

        &Right {
            transform-origin: top right;
            right: 0;

            &::after {
                left: unset;
                right: 20px;
            }
        }
    }

}

.normal {
    height: 48px;
    min-width: 154px;
    padding: 12px;
    font-size: 16px;

    svg {
        height: 24px;
        width: 24px;
    }
}

.small {
    height: 40px;
    min-width: 154px;
    padding: 8px 12px;
    font-size: 14px;

    svg {
        height: 20px;
        width: 20px;
    }
}

.resizing {
    @media (max-width: $sm) {
        min-width: auto;
        height: auto;
        padding: 6px 8px;
    }
}

.primary {
    background-color: $buttons-primary-default;
    color: $white;
    border: 1px solid $buttons-primary-default;

    &:hover {
        background-color: $buttons-primary-hover;
        border: 1px solid $buttons-primary-hover;
    }

    &:active,
    &:focus {
        background-color: $buttons-primary-pressed;
        border: 1px solid $buttons-primary-pressed;
    }

    &:disabled {
        cursor: default;
        background-color: $buttons-primary-disabled;
        color: $icon-primary-border-checkbox-radio-button;
        border: 1px solid $buttons-primary-disabled;
    }
}

.secondary {
    background-color: transparent;
    color: $buttons-primary-default;
    border: 1px solid $buttons-primary-default;

    &:hover {
        border: 1px solid $buttons-primary-hover;
        color: $buttons-primary-hover;
    }

    &:active,
    &:focus {
        border: 1px solid $buttons-primary-pressed;
        color: $buttons-primary-pressed;
    }

    &:disabled {
        cursor: default;
        color: $icon-primary-border-checkbox-radio-button;
        border: 1px solid $buttons-primary-disabled;
    }
}

.canceled {
    background-color: transparent;
    color: $alert;
    border: 1px solid $alert;

    &:hover {
        border: 1px solid $alert-hover;
        color: $alert-hover;
    }

    &:active,
    &:focus {
        border: 1px solid $alert-pressed;
        color: $alert-hover;
    }

    &:disabled {
        cursor: default;
        color: $icon-primary-border-checkbox-radio-button;
        border: 1px solid $buttons-primary-disabled;
    }
}