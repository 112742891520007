@import "../../../../../assets/scss/helpers/variables";
@import "../../../../../assets/scss/helpers/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 976px;
  height: 85vh;

  @media (max-width: $sm) {
    width: 760px;
    height: 88vh;
    gap: 14px;
  }
}

.btnsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.title {
  font-size: 26px;
  line-height: 33px;
  font-weight: 600;
  margin-bottom: 8px;

  @media (max-width: $sm) {
    font-size: 20px;
    line-height: 25px;
  }
}

.subTitle {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
}

.warnWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: $sm) {
    gap: 14px;
  }
}

.listContainer {
  @include shadows;
  padding: 10px 0;

  .modelsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .search {
      align-self: center;
      width: 530px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 30px;
      margin: auto 0;

      @media (max-width: $md) {
        width: 480px;
        font-size: 18px;
      }

      @media (max-width: $sm) {
        gap: 12px;
      }
    }

    .text {
      font-weight: 400;
      font-size: 20px;
      color: $text-secondary-secondary;

      @media (max-width: $md) {
        font-size: 18px;
      }

      @media (max-width: $sm) {
        font-size: 16px;
      }
    }
  }
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}