@import "../../../../assets/scss/helpers/variables";

.container {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 4px 12px;
  border-radius: 20px;
  border: 1px solid $line-primary;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    span {
      font-weight: 700;
      line-height: 18px;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}


.btnsContainer {
  position: relative;
  display: flex;
  gap: 8px;
}