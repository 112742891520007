@import "../../../assets/scss/helpers/variables";

.wrapper {
    margin: 80px auto 0;
    width: 462px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
}

.text {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.44;
    color: $text-secondary-secondary;
    @media (max-width: $md) {
        font-size: 18px;
    }

    @media (max-width: $sm) {
        font-size: 16px;
    }
}
