@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid $icons-primary;
  border-radius: 4px;
}

.titleWrap {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 24px;
    height: 24px;
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: $text-primary-black;
  }
}

.text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $text-primary-black;
}