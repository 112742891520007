@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.container {
  min-height: 400px;
  width: 1080px;
}

.title {
  display: inline-block;
  margin-bottom: 40px;
  padding-bottom: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: $gray-concrete;
  border-bottom: 1px solid $buttons-primary-default;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.description {
  max-width: 70%;
}

.link {
  display: flex;
  flex-direction: row;
  gap: 8px;
  a {
    @include link;
    font-size: 16px;
  }
}

.attachments {
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $text-secondary-secondary;
  }
}
