@import "../../../../assets/scss/helpers/variables";

.container {
  margin-top: 16px;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $line-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  transition: 0.3s;

  &Active {
    border-bottom: 1px solid $buttons-primary-default;
  }
}

.icon {
  width: 24px;
  height: 24px;
  padding-top: 2px;
}

.input {
  width: 100%;
  border: 0;
  outline: none;
  background: none;
  padding: 8px 64px 8px 0px;
  font-size: 14px;
  box-sizing: border-box;
  height: 38px;
  background-color: transparent;
  transition-delay: 1s;

  &::content {
    padding-left: 50px;
  }

  @media (max-width: $sm) {
    font-size: 14px;
  }

  &:focus::placeholder {
    transition-delay: 0s;
    opacity: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 600000s ease-in-out 0s !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-text-fill-color: $text-header-dark !important;
  }
}

.button {
  display: block;
  width: 190px;
  font-size: 14px;
  font-weight: 400;
  color: $buttons-primary-default;
  opacity: 0;
  visibility: hidden;
  transition: 0.35s;

  &Active {
    opacity: 1;
    visibility: visible;

    &:hover {
      color: $buttons-primary-hover;
    }

    &:focus {
      color: $buttons-primary-pressed;
    }
  }
}

.wrapper {
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 1;
  width: 100%;
  min-height: 30px;
  padding: 20px 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.35s;
  border-radius: 2px;
  background: $white;
  box-shadow:
    2px 10px 24px 0px rgba(14, 56, 100, 0.07),
    1px 1px 1px 1px rgba(0, 9, 24, 0.05);
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-content: start;

  &Active {
    top: 40px;
    opacity: 1;
    visibility: visible;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
  gap: 12px;

  p {
    font-size: 14px;
    font-weight: 400;
    color: $text-secondary-secondary;
  }
}