@import "../../../../../assets/scss/helpers/mixins";

.button {
    @include icon-button-with-hint;
}

.receiveButton {
    border: 1px solid $buttons-primary-default;

    &:disabled {
        border: 1px solid $icon-primary-border-checkbox-radio-button;
        background-color: transparent;

        svg path {
            stroke: $icon-primary-border-checkbox-radio-button;
        }
    }
}