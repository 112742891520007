@import "../../../../../assets/scss/helpers/mixins";
.button {
  @include icon-button-with-hint;
}

.approve {
  border: 1px solid $buttons-primary-default;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}
