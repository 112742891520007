@import "../../../assets/scss/helpers/variables";
.label {
  display: block;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 25px;
  max-width: 52px;
  &New {
    background-color: $buttons-primary-default;
    color: $white;
  }
  &Soon {
    background-color: $background-grey;
    color: $buttons-primary-default;
  }
  &Empty {
    height: 20px;
  }
}
