@import "../../../../assets/scss/helpers/variables";

.container {
  position: relative;
  height: 32px;
  width: 32px;

  & svg:first-of-type {
    display: block;
    height: 100%;
    width: 100%;
    color: $buttons-primary-default;
  }

  & svg:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    height: 13px;
    width: 13px;
    color: $buttons-primary-default;
  }
}