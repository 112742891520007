@import "../../../../../assets/scss/helpers/variables";
@import "../../../../../assets/scss/helpers/mixins";

.weightLabel {
  @include label;
  gap: 0;
  align-items: center;
  width: 100%;

  @media (max-width: $sm) {
    height: 60px;
  }

  label {
    padding: 18px 0px;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 34% 50% 16%;
    cursor: pointer;

    @media (max-width: $sm) {
      grid-template-columns: 34% 48% 18%;
    }
  }

  .weight {
    display: flex;
    gap: 14.5px;
    height: 40px;
    align-items: center;
    margin-left: 10px;

    .weightName {
      padding-right: 24px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 2px;

      h3 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        color: $text-header-dark;

        @media (max-width: $sm) {
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: $text-secondary-secondary;

        @media (max-width: $sm) {
          font-size: 12px;
        }
      }
    }
  }

  .weightDescription,
  .weightDate {
    padding-right: 24px;
    overflow: hidden;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: $text-header-dark;

      @media (max-width: $sm) {
        font-size: 12px;
      }
    }
  }
}

.weightDisabled {
  position: relative;
  transition: 0.35s;
  cursor: default;

  h3 {
    opacity: 0.5;
  }

  p {
    opacity: 0.5;
  }

  label {
    cursor: default;
  }

  &:hover {
    background-color: transparent;

    [data-component="hidden"]:not(:checked)+[data-component="radio"]::after {
      transform: scale(0);
    }

    .hint {
      z-index: 1;
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }

  .hint {
    @include large-hint-in-row;
  }

  &:last-of-type {
    .hint {
      top: -35%;

      &::after {
        top: 100%;
        left: calc(10% - 6px);
        transform: rotate(180deg);
      }
    }
  }
}