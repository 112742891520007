@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 20px 12px;
  background-color: $rows-light;
  border: 1px solid $icons-primary;
  border-radius: 2px;

  div {
    display: flex;
    flex-direction: row;
    gap: 8px;

    p {
      padding-top: 1px;
      font-size: 16px;
      font-weight: 400;
      color: $text-header-dark;
    }

    svg {
      color: $icons-primary;
      height: 24px;
      width: 24px;
    }
  }
}

.email {
  @include email;
  text-decoration: none;
  padding-top: 1px;
  font-size: 16px;
}
