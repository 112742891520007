@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 976px;
  height: 70vh;

  @media (max-width: $sm) {
    width: 760px;
    height: 80vh;
  }
}

.title {
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 8px;

  @media (max-width: $sm) {
    font-size: 20px;
    line-height: 25px;
  }
}

.subtitle {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.listContainer {
  @include shadows;
  padding: 10px 0;

  .modelsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ul li:last-of-type:not(:only-of-type) {
    aside {
      &::before {
        transform: rotate(180deg);
        bottom: 35px;
      }

      &::after {
        top: 8px;
      }

      &:active::before,
      &:hover:before {
        transform: rotate(180deg);
        bottom: 48px;
      }

      &:active::after,
      &:hover:after {
        top: -34px;
      }
    }
  }
}

.spinner {
  width: 976px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search {
  align-self: center;
  width: 552px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
}

.text {
  font-weight: 400;
  font-size: 20px;
  color: $text-secondary-secondary;

  @media (max-width: $md) {
    font-size: 18px;
  }

  @media (max-width: $sm) {
    font-size: 16px;
  }
}