@import '../../../assets/scss/helpers/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.description {
    font-size: 12px;
    line-height: 20px;
    color: $text-secondary-secondary;
}

.author {
    display: flex;
    align-items: center;
    gap: 8px;
}

.authorName {
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
}

.authorUser {
    svg {
        color: $icons-primary;
    }
}

.authorAdmin {
    svg {
        color: $text-secondary-secondary;
    }
}