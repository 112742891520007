@import "../../../../../assets/scss/helpers/variables";
.button {
  position: relative;
  padding-top: 4px;
  z-index: 10;
  &:hover {
    .description {
      opacity: 0.8;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }
  .description {
    position: absolute;
    top: 25px;
    right: -90px;
    padding: 8px 10px;
    display: inline-block;
    background-color: $alert;
    border: 1px solid $alert;
    color: $white;
    font-size: 16px;
    line-height: 1;
    transition: all 0.2s;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.1);
    transform-origin: top;
    pointer-events: none;
    white-space: nowrap;
    &::after {
      content: "";
      position: absolute;
      top: -5px;
      left: calc(73% - 42px);
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: $alert;
      border-top: 0;
    }
  }
}
