@import "../../../../assets//scss/helpers/mixins";

.container {
  width: 490px;
}

.title {
  font-size: 18px;

  span {
    font-weight: 700;
  }
}

.info {
  margin: 8px auto 12px;
}

.text {
  margin-top: 8px;
  margin-bottom: 28px;
  font-size: 14px;
  line-height: 24px;

  span {
    font-weight: 700;
  }
}

.link {
  @include link;
}

.buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}