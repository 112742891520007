@import '../../../../../assets/scss/helpers/mixins';

.colInner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modelOpen {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.subTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.git {
  display: flex;
  align-items: center;
  gap: 10px;

  .link {
    @include link;
    word-wrap: break-word;
    overflow: hidden;
  }

  .isDisabled {
    pointer-events: none;
    user-select: none;
    filter: blur(3px)
  }

  span {
    font-size: 0;
    line-height: 0;
  }

  .refresh {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
}

.statusWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: $text-secondary-secondary;
  }
}