.title {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 20px;
  width: 600px;
}

.btnsWrapper {
  display: flex;
  justify-content: end;
  gap: 20px;
}
