@import "../../../assets/scss/helpers/variables";

.wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.pagesList {
  display: flex;
  gap: 8px;
}

.pagesItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  font-size: 12px;
  line-height: 1;
  color: $text-header-dark;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;
}

.pagesItem:hover {
  border: 1px solid $line-secondary;
  transition: 0.35s;
}

.pagesItem:active {
  border: 1px solid $line-primary;
}

.pagesItemActive {
  background-color: $buttons-primary-default;
  color: $white;
  border: 1px solid $buttons-primary-default;
  cursor: default;
}

.pagesItemHide {
  display: none;
}

.pagesItemDotted {
  position: relative;
  cursor: default;
  pointer-events: none;

  &::before {
    content: "...";
    position: absolute;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding-bottom: 3px;
    line-height: 1;
    background-color: $white;
    top: 0;
    left: 0;
    font-size: 16px;
  }
}

.pagesItemActive:hover,
.pagesItemActive:active {
  border: 1px solid $buttons-primary-default;
}

.arrow {
  display: flex;
  cursor: default;
  transition: 0.35s;

  svg {
    color: $icon-primary-disabled;
  }
}

.arrowActive {
  cursor: pointer;

  svg {
    color: $icon-primary-border-checkbox-radio-button;
  }

  &:hover {
    svg {
      color: $buttons-primary-hover;
    }
  }

  &:hover {
    svg {
      color: $buttons-primary-pressed;
    }
  }
}