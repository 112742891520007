@import "../../../../../assets/scss/helpers/mixins";

.button {
  @include icon-button;
}

.refresh {
  border: 1px solid $line-primary;
  color: $text-secondary-secondary;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}