@import "../../../../assets/scss/helpers/variables";

.wrapper {
  width: 968px;

  @media (max-width: $sm) {
    width: 760px;
  }
}

.title {
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;

  @media (max-width: $sm) {
    font-size: 20px;
    line-height: 25px;
  }
}

.warnInfo {
  margin-top: 12px;
}

.formWrapper {
  padding-top: 32px;
  max-height: 70vh;
  padding-right: 5px;

  overflow-y: auto;
  visibility: visible;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border-left: 0;
    border-right: 0;
    background-color: $line-secondary;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 44px;
}

.btnsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 28px;
}