@import "../../../assets/scss/helpers/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 18vh;
}

.text {
  width: 520px;
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 26px;
    color: $text-secondary-secondary;
  }

  p {
    margin-top: 16px;
    font-weight: normal;
    font-size: 18px;
    color: $text-secondary-secondary;
  }
}