.container {
  margin-top: 45px;
  height: 16px;
  width: 100%;
  outline: 1px solid #137cf0;
  border-radius: 10px;
  animation: bar linear 5.5s infinite;
  max-width: 90vw;
  margin: 20px auto;
}

.item {
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  animation: dots linear 5.5s infinite;
}

%circle {
  border-radius: 50%;
  background-color: #137cf0;
  position: absolute;
  top: 2px;
}

.small {
  @extend %circle;
  width: 8px;
  height: 8px;
  top: 4px;
  animation: small linear 5.5s infinite;
}

.medium {
  @extend %circle;
  width: 10px;
  height: 10px;
  top: 3px;
  animation: medium linear 5.5s infinite;
}

.big {
  @extend %circle;
  width: 12px;
  height: 12px;
}

@keyframes bar {
  0% {
    opacity: 0.1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes dots {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(50%);
  }
  50% {
    transform: translateX(99%);
  }
  75% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes small {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-44px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(44px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes medium {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-22px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(22px);
  }
  100% {
    transform: translateX(0);
  }
}
