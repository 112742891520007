@import '../../../../../assets/scss/helpers/mixins';
@import '../../../../../assets/scss/helpers/variables';

.link {
  @include link;
  font-size: 14px;
  text-decoration: none;

  @media (max-width: $sm) {
    font-size: 12px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $text-secondary-secondary;
}