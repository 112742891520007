@import "../../../../../assets/scss/helpers/mixins";

.button {
    @include icon-button-with-hint;
    border: 1px solid $line-primary;
    position: relative;
    color: $text-secondary-secondary;

    svg {
        width: 22px;
        height: 22px;
    }
}

.deleteButton:hover {
    border: 1px solid $alert;
    background-color: $alert;
}

.deleteButton:disabled {
    background-color: transparent;
    border: 1px solid $buttons-primary-disabled;
    cursor: default;
}