@import '../../../../assets/scss/helpers/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid $line-primary;
}

.topWrapper {
  display: flex;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.addParamWrap {
  position: relative;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $line-secondary;
}

.paramsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: $text-header-dark;
}