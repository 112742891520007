@import '../../../../assets/scss/helpers/variables';

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $buttons-primary-default;
  }
}
