@import "../../../assets/scss/helpers/variables";

.container {
  margin-top: 32px;
  padding: 24px;
  border-radius: 8px;
  background: var(--text-header-light, #fff);
  box-shadow: 0px 2px 4px 0px rgba(14, 56, 100, 0.08);
}

.table {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-secondary, #e0e3e6);
}

.columns {
  padding: 8px;
  display: grid;
  
  border-bottom: 1px solid $line-primary;
  color: $text-secondary-secondary;
  font-size: 14px;
  line-height: 1.28;
  @media (max-width: $sm) {
    font-size: 10px;
  }

  &Admin {
    grid-template-columns: 13.9% 13.9% 13.9% 13.9% 17.5% 13.9% 12%;
  }

  &User {
    grid-template-columns: 15% 55% 15% 15%;
  }
}

.rows {
  display: flex;
  flex-direction: column;
  & li:nth-child(even) {
    background-color: $background-grey;
  }
}
