@import "../helpers/variables";
@import "../helpers/mixins.scss";

body {
  overflow-x: hidden;
  font-family: $font-family-base;
  background-color: $background-grey;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    border-left: 0;
    border-right: 0;
    background-color: $line-secondary;
  }
}