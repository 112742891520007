@import "../../../../assets/scss/helpers/variables";

.button {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $buttons-primary-default;
  border: 1px solid $line-primary;
  border-radius: 50%;
  background-color: $white;
  transition: background-color, 0.35s, color 0.35s, border 0.35s;

  svg {
    fill: transparent;
    color: currentColor;
  }

  svg path {
    stroke: currentColor;
  }

  &Prev {
    svg {
      transform: rotate(180deg);
    }
  }

  &:hover,
  &:active {
    color: $white;
  }

  &:hover {
    border: 1px solid $buttons-primary-hover;
    background-color: $buttons-primary-hover;
  }

  &:active {
    border: 1px solid $buttons-primary-pressed;
    background-color: $buttons-primary-pressed;
  }

  &:disabled {
    color: $icon-primary-disabled;
    border: 1px solid $buttons-primary-disabled;
    background-color: $white;
    cursor: auto;
  }

}