.title {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 40px;
}

.inputWrapper {
    width: 454px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
}
