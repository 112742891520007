@import "../../../../assets/scss/helpers/variables";
.btn {
  padding: 8px;
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-hover;
  font-size: 18px;
  transition: 0.35s;
  &:hover {
    color: $buttons-primary-default;
    background-color: $background-secondary;
  }
}

.btnIcon {
  margin-left: 20px;
}
