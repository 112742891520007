@import '../../../../../assets/scss/helpers/variables';

.container {
  position: relative;

  .input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid $line-primary;
    outline: none;
    background: none;
    padding: 8px 64px 8px 0px;
    font-size: 16px;
    box-sizing: border-box;
    height: 38px;
    background-color: transparent;

    &:focus {
      border-bottom: 1px solid $buttons-primary-default;
    }

    &-ms-reveal {
      display: none;
    }

    &NotValid {
      border-bottom: 1px solid $alert;

      &:focus {
        border-bottom: 1px solid $alert;
      }
    }
  }
}

.icons {
  position: absolute;
  right: 6px;
  bottom: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 18px;
}

.tickIconDisabled {
  cursor: default;

  svg path {
    stroke: $icon-primary-border-checkbox-radio-button;
  }
}

.error {
  position: absolute;
  padding-top: 4px;
  color: $alert;
  line-height: 19px;
  font-size: 14px;
}