@import "../../../assets/scss/helpers/variables";

.container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  width: 73%;
  & > svg {
    display: block;
    margin-top: 5px;
    height: 32px;
    width: 32px;
    color: $buttons-primary-default;
  }
}

.title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 90%;
  h1 {
    font-size: 26px;
    font-weight: 600;
    color: $text-header-dark;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: $text-secondary-secondary;
  }
}

.info {
  display: flex;
  gap: 4px;
  align-items: center;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $gray-concrete;
  }

  .category {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.dot {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $line-primary;
}
