@import "../../../../assets/scss/helpers/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 976px;
  height: 80vh;

  @media (max-width: $sm) {
    width: 760px;
    height: 80vh;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    color: $text-header-dark;

    @media (max-width: $sm) {
      font-size: 20px;
    }
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    color: $text-secondary-secondary;
  }

  .bold {
    font-weight: 700;
  }
}