@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.wrapper {
    width: 1048px;
}

.title {
    font-size: 26px;
    line-height: 33px;
    font-weight: 600;
    margin-bottom: 8px;
}

.subtitle {
    font-size: 16px;
    line-height: 23px;
    color: $text-body-dark;
    margin-bottom: 12px;
}

.description {
    color: $text-secondary-secondary;
    font-size: 12px;
    line-height: 24px;

    span {
        color: $alert;
    }
}

.textAreaWrapper {
    margin-bottom: 44px;
}

.btnWrapper {
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 28px;
}

.scrollContent {
    margin-top: 28px;
    max-height: 50vh;
    overflow-y: scroll;
    @include scroll;
}