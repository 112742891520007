@import "../../../../assets/scss/helpers/variables";

.switch {
  display: block;
  width: 44px;
  height: 24px;
  background-color: $line-primary;
  border-radius: 24px;
  position: relative;
  cursor: pointer;
  transition: 0.35s;

  &:hover::after {
    transform: scale(1.1);
  }

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    display: block;
    background-color: $white;
    border-radius: 50%;
    transition: 0.35s;
  }
}

input:checked~.switch {
  background-color: $buttons-primary-default;

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 22px;
    width: 20px;
    height: 20px;
    display: block;
    background-color: $white;
    border-radius: 50%;
  }
}

input:disabled~.switch {
  opacity: 0.5;
  pointer-events: none;
}