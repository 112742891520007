@import "../../../assets/scss/helpers/variables";

.option {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .label {
        display: inline-flex;
        align-self: start;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        user-select: none;
    }

    .description {
        font-size: 12px;
        line-height: 20px;
        color: $text-secondary-secondary;
    }

    .labelDisabled,
    .descriptionDisabled {
        pointer-events: none;
        opacity: 0.5;
    }
}
