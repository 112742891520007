.addProjectWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnWrapper {
  display: flex;
  justify-content: end;
  margin-top: 12px;
}

.btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.btnIcon {
  display: flex;
  align-items: center;
}

