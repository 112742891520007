@import '../../../assets/scss/helpers/variables';

.wrapper {
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 44px 12px;
}

.item {
  display: flex;
  gap: 5px;
}

.dockerImage {
  padding: 12px 22.5px;
  border: 1px solid $line-primary;
  border-radius: 8px;
  display: flex;
  align-items: start;
  justify-content: center;
  align-items: center;

  .info {
    padding-left: 4px;
    color: $success;
  }

  @media (max-width: $sm) {
    padding: 12px;
  }
}

.bold {
  font-weight: 700;
}

.btnWrapper {
  margin-left: -22.5px;
  width: 24px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-bottom: 10px;

  @media (max-width: $sm) {
    margin-left: -16px;
  }
}