@import "../../../assets/scss/helpers/variables";

.progressBar {
    display: flex;
    gap: 60px;
}

.step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .count {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid $line-primary;
        color: $white;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        background-color: $white;
        transform-style: preserve-3d;

        span {
            color: $text-body-dark;
        }

        &::after {
            content: "";
            width: 170px;
            border-bottom: 2px solid $line-primary;
            position: absolute;
            left: 39px;
            top: 50%;
            transform: translateZ(-10px);
        }
    }

    &:last-child {
        .count {
            &::after {
                display: none;
            }
        }
    }

    .text {
        font-size: 12px;
        line-height: 16px;
        color: $text-secondary-secondary;
        display: flex;
        align-items: center;
        text-align: center;
        gap: 8px;
    }
}

.stepActive,
.stepPast {
    .count {
        background-color: $buttons-primary-default;
        border: 1px solid $buttons-primary-default;
    }
}

.stepActive {
    .count {
        color: $white;
        font-weight: 700;

        span {
            color: currentColor;
        }
    }

    .text {
        color: $text-header-dark;
    }
}

.stepPast {
    .count {
        &::after {
            border-bottom: 2px solid $buttons-primary-default;
        }
    }
}

.stepSmaller {
    .count {
        &::after {
            width: 145px;
        }
    }
}

.roundHasFinished {
    .count {
        color: $buttons-primary-default;
        background-color: $white;
        border: 1px solid $line-primary;

        &::after {
            border-bottom: 2px solid $line-primary;
        }
    }
}

.stepSkipped {
    .count {
        background-color: $background-grey;
        border: 1px solid $buttons-primary-disabled;
    }

    .text {
        color: $line-primary;
    }
}

.stepError {
    .count {
        background-color: $alert;
        border: 1px solid $alert;

        &::after {
            width: 160px;
        }
    }

    .text {
        color: $alert;

        span {
            display: flex;
        }

        span svg path {
            stroke: $alert;
        }
    }
}