@import "../../../../assets/scss/helpers/mixins";

.col {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: calc((1080 - 40 - 233) / 1080 * 100%);
  @media (max-width: $sm) {
    width: 520px;
  }
}

.contentBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subTitle {
  margin-bottom: 12px;
  color: $text-secondary-secondary;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  @media (max-width: $sm) {
    font-size: 12px;
  }
}

.addButtonWrap {
  align-self: flex-end;
}
