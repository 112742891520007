@import "../../../assets/scss/helpers/mixins";

.title {
    color: $text-secondary-secondary;
    font-size: 14px;
    margin-bottom: 12px;

    @media (max-width: $sm) {
        font-size: 12px;
        margin-bottom: 8px;
    }
}

.attachments {
    display: grid;
    grid-template-columns: repeat(auto-fit, 344px);
    gap: 12px 20px;

    @media (max-width: $sm) {
        grid-template-columns: repeat(auto-fit, 390px);
        gap: 12px 6px;
    }
}

.attachment {
    max-width: 380px;
    height: 64px;
    padding: 12px;
    align-items: center;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    border: 1px solid $line-primary;

    @media (max-width: $sm) {
        max-width: 390px;
    }

    &Disabled {
        cursor: default;
        pointer-events: none;
        color: $icon-primary-border-checkbox-radio-button;
        text-decoration: none;
    }

    .info {
        display: grid;
        grid-template-areas: "icon name" "icon format";
        grid-template-columns: 40px auto;
        column-gap: 12px;
        opacity: 1;
        width: 100%;

        &Disabled {
            opacity: 0.7;
        }

        .icon {
            grid-area: icon;
            width: 40px;
            height: 40px;
            background-color: $background-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .name {
            grid-area: name;
            max-width: 95%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $text-header-dark;
            font-weight: 400;
            font-size: 14px;
        }

        .format {
            grid-area: format;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $text-secondary-secondary;
            font-weight: 400;
            font-size: 12px;
        }
    }
}
