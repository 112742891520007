@import '../../../../assets/scss/helpers/variables';
@import '../../../../assets/scss/helpers/mixins';

.appeal {
  display: grid;
  padding: 14px 8px;
  height: 76px;
  border-radius: 4px;
  cursor: pointer;

  &Admin {
    grid-template-columns: 13.9% 13.9% 13.9% 13.9% 17.5% 13.9% 12%;
  }

  &User {
    grid-template-columns: 15% 55% 15% 15%;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
  width: 120px;
}

.text {
  color: $text-header-dark;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.number {
  overflow: hidden;
  color: $text-header-dark;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

.primary {
  color: $text-header-dark;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.secondary {
  color: $text-secondary-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.position {
  color: $text-body-dark;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.email {
  @include email;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.date {
  color: $text-body-dark;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
}
