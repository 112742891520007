@import "../../../../assets/scss/helpers/variables";

.spinner {
    align-self: center;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1.7px solid $icons-primary;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $icons-primary transparent transparent transparent;
}

.spinner div:nth-child(1) {
    animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
    animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}