@import "../../../../../assets/scss/helpers/mixins";

.activateButton {
  @include icon-button-highlight;
  border: 1px solid $buttons-primary-hover;
  color: $text-secondary-secondary;
}

.deactivateButton {
  @include icon-button-with-hint;
  border: 1px solid $line-primary;
  color: $text-secondary-secondary;
}

.deactivateButton:disabled,
.activateButton:disabled {
  background-color: transparent;
  border: 1px solid $buttons-primary-disabled;
  cursor: default;
}