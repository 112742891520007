@import '../../../../../assets/scss/helpers/mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 307px;
  border-radius: 8px;
  background-color: #F7F7F8; // TODO palette
}

.metric {
  @include card;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 4;

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #697381;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $text-header-dark;
  }
}