@import "../../../assets/scss/helpers/variables";

.text {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $buttons-primary-default;
  @media (max-width: $sm) {
    font-size: 12px;
  }
}
