@import "../../../../assets/scss/helpers/variables";

.container {
  position: absolute;
  width: 100%;
  top: 70px;
  padding: 8px 16px;
  background-color: $rows-light;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(14, 56, 100, 0.08);
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: 0.35s;
  opacity: 0;
  visibility: hidden;

  @media (max-width: $sm) {
    top: 80px;
  }

  &Active {
    opacity: 1;
    visibility: visible;
  }
}

.close {
  position: absolute;
  right: 8px;
  top: 8px;
}

.input {
  margin-top: 10px;
  width: 100%;
  border: 0;
  outline: none;
  background: none;
  padding: 8px 64px 8px 0px;
  font-size: 16px;
  box-sizing: border-box;
  height: 38px;
  background-color: transparent;

  @media (max-width: $sm) {
    font-size: 14px;
  }
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $line-primary;
}

.textArea {
  width: 100%;
  border: 0;
  outline: none;
  background: none;
  padding: 8px 0px;
  font-size: 16px;
  box-sizing: border-box;
  min-height: 28px;
  background-color: transparent;
  overflow: hidden;
  resize: none;

  @media (max-width: $sm) {
    font-size: 14px;
  }
}

.icon {
  padding-top: 6px;
  color: $text-secondary-secondary;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.error {
  color: $alert;
  font-size: 14px;
  font-weight: normal;
}

.button {
  align-self: flex-end;
  font-size: 16px;
  font-weight: 400;
  color: $buttons-primary-default;
}