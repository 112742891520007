@import '../../../../assets/scss/helpers/mixins';

.wrapper {
    width: fit-content
}

.title {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    font-size: 16px;
    line-height: 23px;
}

.link {
    @include link;
    font-size: inherit;
    line-height: inherit;
}