@import '../../../../assets/scss/helpers/variables';
@import '../../../../assets/scss/helpers/mixins';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  align-self: center;
  position: relative;
  gap: 8px;
  transition: 0.35s;
  position: relative;

  svg {
    transition: 0.35s;
  }

  @media (max-width: $sm) {
    font-size: 14px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &:hover {
    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }

  .description {
    @include hint;
    z-index: 1;
    top: 100%;
    right: 0;
    border-radius: 2px;

    @media (max-width: $sm) {
      top: 100%;
      font-size: 12px;
    }

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: unset;
      right: 20px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: $buttons-primary-pressed;
      border-top: 0;
    }
  }
}

.normal {
  font-size: 16px;
  line-height: 1.7;

  svg {
    height: 24px;
    width: 24px;
  }
}

.small {
  font-size: 14px;
  line-height: 1.7;

  svg {
    height: 20px;
    width: 20px;
  }
}

.primary {
  color: $buttons-primary-default;

  svg {
    color: $buttons-primary-default;
  }

  &:hover {
    color: $buttons-primary-hover;

    svg {
      color: $buttons-primary-hover;
    }
  }

  &:active,
  &:focus {
    color: $buttons-primary-pressed;

    svg {
      color: $buttons-primary-pressed;
    }
  }

  &:disabled {
    cursor: default;
    color: $icon-primary-border-checkbox-radio-button;

    svg {
      color: $icon-primary-border-checkbox-radio-button;
    }
  }
}

.canceled {
  color: $alert;

  svg {
    color: $alert;
  }

  &:hover {
    color: $alert-hover;

    svg {
      color: $alert-hover;
    }
  }

  &:active,
  &:focus {
    color: $alert-pressed;

    svg {
      color: $alert-pressed;
    }
  }

  &:disabled {
    cursor: default;
    color: $icon-primary-border-checkbox-radio-button;

    svg {
      color: $icon-primary-border-checkbox-radio-button;
    }
  }
}