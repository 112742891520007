@import "../../../../assets/scss/helpers/variables";

@mixin outlined {
  color: $buttons-primary-default;
  border-color: $line-secondary;
  background-color: transparent;
  cursor: pointer;
}

@mixin contained {
  color: $white;
  background-color: $buttons-primary-default;
  border-color: $buttons-primary-default;
  cursor: pointer;
}

@mixin disabled {
  color: $buttons-primary-default;
  background-color: $background-grey;
  border-color: $background-grey;
}

@mixin crossed {
  padding: 1.5px 11px;
}

.tag {
  box-sizing: border-box;
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  height: 32px;
  border: 1px solid;
  border-radius: 20px;
  font-size: 14px;
  white-space: nowrap;
  user-select: none;
  transition: border 0.35s, color 0.35s, background-color 0.35s;

  &:hover {
    border-color: $buttons-primary-default;
  }

  &:active {
    @include contained;
  }
}

.contained {
  @include contained;
}

.outlined {
  @include outlined;
}

.disabled {
  @include disabled;
}

.crossed {
  @include crossed;
}