@import "../../../assets/scss/helpers/variables";

.container {
  display: flex;
  gap: 8px;

  h3 {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: $buttons-primary-default;
  }
}