@import "../../../../../assets/scss/helpers/mixins";

.button {
    @include icon-button-with-hint;
    line-height: normal;
}

.downloadButton {
    border: 1px solid $buttons-primary-default;

    & .bottom-end {
        @include btn-hint-bottom-end;
    }

    & .top {
        @include btn-hint-top;
    }

    & .top-end {
        @include btn-hint-top-end;
    }

    &:disabled {
        border: 1px solid $icon-primary-disabled;
        background-color: transparent;

        svg path {
            stroke: $icon-primary-disabled;
        }
    }
}