.wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
}

.starsWrapper {
    display: flex;
    gap: 10px;
}

.text {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: #3F454D;
}

.iconActive {
    cursor: pointer;
}
