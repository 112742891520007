@import '../../../../../../../assets/scss/helpers/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.title,
.subtitle {
    font-size: 12px;
    line-height: 20px;
}

.title {
    font-weight: 700;
    color: $buttons-primary-default;
}

.subtitle {
    color: $text-secondary-secondary;
}

.titleWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}
