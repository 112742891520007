@import "../../../../../assets/scss/helpers/variables";
.wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    max-width: 450px;
    @media screen and (max-width: 1439px) {
        margin-bottom: 5px;
    }
}

.checkbox {
    position: relative;
    display: inline-flex;
    margin-left: 36px;
    padding-top: 2px;
    cursor: pointer;
    user-select: none;
    .square {
        cursor: pointer;
        &::before {
            position: absolute;
            top: 0;
            left: -36px;
            width: 22px;
            height: 22px;
            content: " ";
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.875 2.125C2.18464 2.125 1.625 2.68464 1.625 3.375V14.625C1.625 15.3154 2.18464 15.875 2.875 15.875H14.125C14.8154 15.875 15.375 15.3154 15.375 14.625V3.375C15.375 2.68464 14.8154 2.125 14.125 2.125H2.875ZM0.375 3.375C0.375 1.99429 1.49429 0.875 2.875 0.875H14.125C15.5057 0.875 16.625 1.99429 16.625 3.375V14.625C16.625 16.0057 15.5057 17.125 14.125 17.125H2.875C1.49429 17.125 0.375 16.0057 0.375 14.625V3.375Z' fill='%23ADB3C2'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .content {
        position: relative;
        top: -4px;
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
        line-height: 160%;
        color: $icon-primary-border-checkbox-radio-button;
        @media screen and (max-width: 1687px) {
            font-size: 12px;
        }
    }
    .input {
        position: absolute;
        visibility: hidden;
        appearance: none;
        &:checked {
            & + .square::before {
                border-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.652 5.39643C12.9163 5.61845 12.9506 6.01269 12.7286 6.277L7.47857 12.527C7.36221 12.6655 7.19155 12.7468 7.01067 12.7499C6.8298 12.753 6.65646 12.6776 6.53544 12.5431L4.28544 10.0431C4.05453 9.78653 4.07533 9.39135 4.3319 9.16044C4.58847 8.92953 4.98365 8.95033 5.21456 9.2069L6.98373 11.1726L11.7714 5.47301C11.9935 5.2087 12.3877 5.17442 12.652 5.39643Z' fill='%23247EE0'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.875 2.125C2.18464 2.125 1.625 2.68464 1.625 3.375V14.625C1.625 15.3154 2.18464 15.875 2.875 15.875H14.125C14.8154 15.875 15.375 15.3154 15.375 14.625V3.375C15.375 2.68464 14.8154 2.125 14.125 2.125H2.875ZM0.375 3.375C0.375 1.99429 1.49429 0.875 2.875 0.875H14.125C15.5057 0.875 16.625 1.99429 16.625 3.375V14.625C16.625 16.0057 15.5057 17.125 14.125 17.125H2.875C1.49429 17.125 0.375 16.0057 0.375 14.625V3.375Z' fill='%23247EE0'/%3E%3C/svg%3E");
            }
        }
    }
}

.agreement {
    color: $buttons-primary-default;
    text-decoration: underline;
}
