@import '../../../../../../../assets/scss/helpers/variables';

.wrapper {
    display: flex;
    flex-direction: column;
}

.dockerWrapper+button {
    align-self: start;
}

.btnWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.dockerWrapper,
.weightWrapper {
    padding: 8px 12px;
    border: 1px solid $line-primary;

    .title {
        color: $text-body-dark;
        font-size: 12px;
        line-height: 20px;
    }

    .text {
        font-size: 16px;
        line-height: 23px;
    }
}

.dockerWrapper {
    border-radius: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.weightWrapper {
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 12px;
    cursor: pointer;
}