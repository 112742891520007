@import "../../../../assets/scss/helpers/variables";

.tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 0;
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  @media (max-width: $sm) {
    font-size: 12px;
  }

  .label {
    text-transform: uppercase;
    color: $text-header-dark;
    transition: 0.35s;

    &:hover {
      color: $buttons-primary-pressed;
    }
  }

  .count {
    color: $text-secondary-secondary;
  }

  .blueCount {
    color: $buttons-primary-default;
    font-weight: 700;
  }

  .new {
    position: absolute;
    top: 5px;
    right: -5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $buttons-primary-default;
  }

  &Disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &Selected::after {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 2px;
    width: 100%;
    background-color: $buttons-primary-default;
    animation: border linear 0.2s;
  }

  &Selected::before {
    content: "";
    position: absolute;
    height: 100%;
    background-color: $background-secondary;
    animation: bg linear 0.2s;
    z-index: 1;
  }
}

.primary {
  position: relative;
  padding: 8px 0;
  display: block;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  user-select: none;
  transition: 0.35s;
  text-transform: none;
  color: $gray-concrete;

  &:hover {
    color: $text-header-dark;
  }

  &Selected {
    color: $text-header-dark;
    font-weight: bold;
    text-shadow: 0 0 0.2px $text-header-dark;
  }
}

@keyframes border {
  from {
    width: 0;
    margin-left: 50%;
    margin-right: 50%;
  }

  to {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@keyframes bg {
  from {
    width: 0;
    opacity: 0.3;
    margin-left: 50%;
    margin-right: 50%;
  }

  50% {
    opacity: 0.4;
  }

  to {
    width: 100%;
    opacity: 0;
  }
}