@import "../../../assets/scss/helpers/variables";

.accordion {
    .summary {
        cursor: pointer;
        padding: 20px;
        font-size: 20px;
        border-bottom: 1px solid $line-primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        transition: 0.35s;
        outline: none;

        @media (max-width: $sm) {
            padding: 16px;
            font-size: 16px;
        }

        &:hover {
            background-color: $background-secondary;
        }
    }

    .content {
        max-height: 0;
        opacity: 0;
        transition: 0.35s;
        visibility: hidden;
        overflow: hidden;
    }

    .summaryActive {
        background-color: $background-secondary;
        border: none;
        outline: none;

        .icon {
            transform: rotate(180deg);
        }
    }

    .input:checked~.content {
        max-height: 5500px;
        opacity: 1;
        overflow: visible;
        visibility: visible;
    }
}