@import '../../../../assets/scss/helpers/variables';
@import '../../../../assets/scss/helpers/mixins';

.wrapper {
  width: 1089px;
  max-height: 80vh;
  overflow: hidden;
  position: relative;

  @media (max-width: $sm) {
    width: 801px;
    max-height: 78vh;
  }
}

.spinnerWrapper {
  width: 1089px;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm) {
    width: 801px;
  }
}

.scrollContent {
  height: 65vh;
  @include scroll;
}

.title {
  font-weight: 600;
  font-size: 26px;
  line-height: 51px;

  @media (max-width: $sm) {
    font-size: 20px;
    line-height: 25px;
  }
}

.modelInfo {
  margin: 40px auto;
}

.topBlock {
  margin-top: 42px;
  margin-bottom: 4px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 32px;
}

.middleBlock {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
}

.types {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
}

.requestCreatorBlock {
  padding-bottom: 100px;
  margin-top: 40px;
}

.btnsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding-bottom: 2px;
}

.row {
  height: 76px;
  align-items: center;
  position: relative;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.primary {
  font-size: 16px;
  color: $text-header-dark;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 30px;

  @media (max-width: $sm) {
    font-size: 14px;
  }
}

.size {
  font-size: 14px;
  color: $text-header-dark;

  @media (max-width: $sm) {
    font-size: 12px;
  }
}

.secondary {
  font-size: 14px;
  color: $text-secondary-secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;

  @media (max-width: $sm) {
    font-size: 12px;
  }
}

.bottomInfo {
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  background-color: $white;
  padding-top: 10px;
  margin-top: 28px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  gap: 20px;
}