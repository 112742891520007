@import "../../../assets/scss/helpers/variables";

.containerFixed {
  position: fixed;
  right: 36px;
  bottom: 6px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  max-height: 300px;
  overflow: hidden;

  li:nth-child(4) {
    opacity: 0;
  }

  .notification {
    width: 350px;
    padding: 24px;
    background-color: $buttons-primary-pressed;
    cursor: pointer;
    margin-bottom: 5px;

    &:hover {
      background-color: $buttons-primary-hover;
    }

    &:active {
      background-color: $notification-pressed;
    }

    &Enter {
      opacity: 1;
      transition: opacity 200ms;
    }

    &EnterActive {
      opacity: 1;
      transition: opacity 200ms;
    }

    &Exit {
      opacity: 1;
      transform: translateX(0);
    }

    &ExitActive {
      z-index: -1;
      opacity: 0;
      transform: translateX(200px);
      transition: opacity 500ms, transform 500ms;
    }

    .name {
      font-size: 16px;
      color: $white;
    }

    .info {
      color: $white;
      font-size: 16px;
    }
  }
}