@import "../../../../assets/scss/helpers/variables";

$primary: $buttons-primary-default;
$gray: $line-primary;

.radioWrapper {
  width: 20px;
  height: 20px;

  @media (max-width: $sm) {
    width: 16px;
    height: 16px;
  }
}

.hidden {
  display: none;
}

.radio {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid $line-primary;
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: $sm) {
    width: 16px;
    height: 16px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 100%;
    background: $primary;
    transform: scale(0);
    transition: all 0.2s ease;
    opacity: 0.08;
    pointer-events: none;
  }
}

.hidden:checked+.radio {
  border-color: $primary;

  &:after {
    transform: scale(0.85);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
}