@import "../../../../assets/scss/helpers/variables";

.container {
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 30px;
  width: 550px;
  background-color: $white;
  padding: 20px;
  box-shadow:
    4px 4px 8px 0px rgba(14, 56, 100, 0.08),
    1px 1px 1px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: 0.35s;

  &Active {
    top: 40px;
    visibility: visible;
    opacity: 1;
  }
}

.search {
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid $line-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.icon {
  width: 24px;
  height: 24px;
  padding-top: 2px;
}

.input {
  width: 100%;
  border: 0;
  outline: none;
  background: none;
  padding: 8px 64px 8px 0px;
  font-size: 16px;
  box-sizing: border-box;
  height: 38px;
  background-color: transparent;

  &::content {
    padding-left: 50px;
  }

  @media (max-width: $sm) {
    font-size: 14px;
  }

  &:focus::placeholder {
    transition-delay: 0s;
    opacity: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 600000s ease-in-out 0s !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-text-fill-color: $text-header-dark !important;
  }
}

.skills {
  display: flex;
  align-content: start;
  flex-wrap: wrap;
  gap: 4px;
}

.empty {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  p {
    font-size: 16px;
    color: $text-secondary-secondary;
  }

  svg {
    height: 70px;
    width: 90px;
  }
}

.buttons {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}