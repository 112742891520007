@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.wrapper {
  width: 660px;
}

.title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}

.subtitle {
  color: $text-secondary-secondary;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 20px;

  span {
    color: $alert;
  }
}

.scrollWrapper {
  margin-top: 28px;
  max-height: 50vh;
  overflow-y: scroll;
  @include scroll;
}
.textAreaWrapper {
  margin-bottom: 44px;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
