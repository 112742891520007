@import '../../../../../assets/scss/helpers/variables';

.contentItem {
  padding: 10px 44px 0px 44px;
  flex: 1 1 auto;
  color: $text-body-dark;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
