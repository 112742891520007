@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.wrapper {
  width: 472px;
}

.title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 16px;
  color: $text-secondary-secondary;
  margin-bottom: 40px;
}

.comment {
  margin-top: 40px;
  font-size: 16px;
  color: $text-header-dark;
  @include text-wrapped;
}

.grid {
  padding: 32px 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0.5fr;
  column-gap: 20px;
  row-gap: 24px;
}

.personalInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gridTitle {
  font-size: 14px;
  color: $text-secondary-secondary;
}

.textPrimary {
  font-size: 16px;
  color: $text-header-dark;
  @include text-wrapped;
}