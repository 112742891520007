@import "../../../../../../assets/scss/helpers/variables";

.tabs {
  border-bottom: 1px solid $background-grey;
  display: flex;
  gap: 60px;
  width: 100%;
  .tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 0;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    &Selected::after {
      content: "";
      position: absolute;
      bottom: -1px;
      height: 2px;
      width: 100%;
      background-color: $buttons-primary-default;
      animation: border linear 0.2s;
    }
    &Selected::before {
      content: "";
      position: absolute;
      height: 100%;
      background-color: $background-secondary;
      animation: bg linear 0.2s;
      z-index: 1;
    }
    .label {
      text-transform: uppercase;
      color: $text-header-dark;
      z-index: 2;
      transition: 0.35s;
      &:hover {
        color: $buttons-primary-pressed;
      }
    }
    .count {
      color: $text-secondary-secondary;
    }
  }
}

@keyframes border {
  from {
    width: 0;
    margin-left: 50%;
    margin-right: 50%;
  }
  to {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@keyframes bg {
  from {
    width: 0;
    opacity: 0.3;
    margin-left: 50%;
    margin-right: 50%;
  }
  50% {
    opacity: 0.4;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}
