@import '../../../../../assets/scss/helpers/mixins';

.button {
    @include icon-button-with-hint;
    color: $white;
}

.approve {
    border: 1px solid $buttons-primary-default;
    background-color: $buttons-primary-default;
}