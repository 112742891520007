@import "../../../../assets/scss/helpers/variables";

.wrapper {
  border: 1px solid transparent;
  position: relative;

  &Disable {
    pointer-events: none;
    opacity: 0.5;
  }

  .container {
    position: relative;
    height: 48px;
    margin-bottom: 4px;
    margin-top: 8px;
    box-sizing: border-box;

    .input {
      position: absolute;
      top: 14px;
      left: 0;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $line-primary;
      outline: none;
      background: none;
      padding: 8px 64px 8px 0px;
      font-size: 16px;
      box-sizing: border-box;
      height: 38px;
      background-color: transparent;

      @media (max-width: $sm) {
        font-size: 14px;
      }

      &:focus {
        border-bottom: 1px solid $buttons-primary-default;
      }

      &:focus::placeholder {
        opacity: 0;
      }

      &:-webkit-autofill ~ .label,
      &:-webkit-autofill:hover ~ .label,
      &:-webkit-autofill:focus ~ .label,
      &:not(:placeholder-shown) ~ .label,
      &:focus ~ .label,
      &:valid ~ .label {
        transform: translateX(0px) translateY(-30px);
        font-size: 12px;
        opacity: 1;
        cursor: default;

        @media (max-width: $sm) {
          font-size: 10px;
        }
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        transition: background-color 600000s ease-in-out 0s !important;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        -webkit-text-fill-color: $text-header-dark !important;
      }

      &-ms-reveal {
        display: none;
      }

      &Disable {
        color: $text-secondary-secondary;
      }

      &NotValid {
        border-bottom: 1px solid $alert;

        &:focus {
          border-bottom: 1px solid $alert;
        }
      }
    }

    .label {
      position: absolute;
      top: 20px;
      transition: 0.6s;
      font-size: 14px;
      color: $text-secondary-secondary;
      cursor: text;
      user-select: none;
      @media (max-width: $sm) {
        font-size: 12px;
      }

      &Hidden {
        opacity: 0;
      }

      &Required {
        &::after {
          content: "*";
          color: $alert;
          padding-left: 2px;
        }
      }
    }
  }

  .error {
    color: $alert;
    user-select: none;
  }

  .ok {
    color: $success;
  }

  .warning {
    color: $warning-main;
  }

  .ok,
  .error,
  .warning {
    padding-top: 4px;
    min-height: 19px;
    font-size: 14px;

    @media (max-width: $sm) {
      font-size: 12px;
    }
  }
}

.grid {
  padding-top: 4px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 62px;

  @media screen and (max-width: $sm) {
    gap: 22px;
  }
}

.forgotten {
  text-align: right;
  color: $buttons-primary-default;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $text-hover;
  }
}

.icon {
  position: absolute;
  right: 1px;
  bottom: 2px;
  cursor: pointer;
}

.button {
  width: 20px;
  position: absolute;
  right: 5px;
  bottom: 25px;
  color: $buttons-primary-default;
}
