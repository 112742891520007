@import "../../../assets/scss/helpers/mixins";
@import "../../../assets/scss/helpers/variables";
.form,
.notice {
  position: relative;
  z-index: 1;
  padding: 40px 65px 40px 72px;
  background-color: $white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 570px;
  @media screen and (max-width: $md) {
    width: 456px;
    padding: 32px;
  }
  @media screen and (max-width: $sm) {
    width: 428px;
    padding: 16px 32px;
  }
}

.title {
  font-weight: 600;
  font-size: 48px;
  color: $text-header-dark;
  @media screen and (max-width: $md) {
    font-size: 40px;
  }
}

.subtitle {
  margin-top: 10px;
  font-size: 16px;
  color: $text-secondary-secondary;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: $md) {
    font-size: 14px;
  }
  a {
    color: $buttons-primary-default;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
    @media screen and (max-width: $md) {
      font-size: 16px;
    }
    &:hover {
      color: $text-hover;
    }
  }
}

.container {
  margin: 45px 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  @media screen and (max-width: $md) {
    margin: 20px 0;
    gap: 0;
  }
}

.subtitleBright {
  text-align: left;
  margin-top: 16px;
  color: $text-secondary-secondary;
}

.paragraph {
  margin-bottom: 40px;
  font-size: 18px;
  text-align: left;
  p {
    font-size: 16px;
  }
  &:first-child {
    margin-top: 40px;
  }
  span {
    font-weight: 700;
  }
}

.paragraphTop {
  margin-top: 40px;
}

.closeBtn {
  position: absolute;
  top: 26px;
  right: 26px;
}

.continueBtn {
  margin-top: 40px;
}

.footer {
  align-self: flex-start;
  max-width: 400px;
  span {
    font-size: 16px;
    color: $text-secondary-secondary;
  }
  button {
    margin-left: 8px;
    text-decoration: underline;
    color: $buttons-primary-default;
    font-size: 18px;
  }
}

.successContainer {
  margin: 40px 0;
  @media screen and (max-width: $md) {
    margin: 20px 0;
  }
}

.captcha {
  margin-bottom: 40px;
  @media screen and (max-width: $md) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: $sm) {
    margin-bottom: 5px;
  }
}
