@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";

.description {
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  color: $text-secondary-secondary;
}

.columns {
  display: grid;
  padding: 8px 0px;
  grid-template-columns: 31% 48.5% auto;
  gap: 24px;
  margin: 12px;
  margin-bottom: 4px;
  color: $text-secondary-secondary;
  border-bottom: 1px solid $line-primary;
  font-size: 14px;
  line-height: 1.28;

  li:first-child {
    margin-left: 32px;

    @media (max-width: $sm) {
      margin-left: 36px;
    }
  }

  @media (max-width: $sm) {
    grid-template-columns: 30.5% 45% auto;
    padding: 4px 0px;
    font-size: 10px;
    margin: 6px;
  }
}

.listContainer {
  @include shadows;
  border: none;
}

.rows {
  li:nth-child(even) {
    background-color: $background-grey;
  }

  // margin-bottom: 5px;
}