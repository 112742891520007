@import '../../../../../assets/scss/app.scss';

.metricsTitle {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  margin-bottom: 24px;
}

.metricsForm {
  margin-bottom: 44px;
}

.form {
  border: 1px solid $line-primary;
  border-radius: 8px;
  padding: 20px;
}

.inputsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.label {
  font-size: 14px;
  line-height: 24px;
  color: $text-secondary-secondary;
  font-family: $font-family-base;
  padding-bottom: 8px;
}

.buttonsWrapper {
  display: flex;
  gap: 24px;
  justify-content: end;
  margin-top: 32px;
}

.metricTags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
}

.styledLabel {
  font-family: $font-family-base;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  top: -6px;

  span {
    color: $alert;
  }
}