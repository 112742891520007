@import "../../../../assets/scss/helpers/variables";

.buttonFixed {
  position: fixed;
  bottom: 60px;
  right: 36px;
  width: 80px;
  height: 80px;
  background-color: $buttons-primary-default;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.35s;

  &:hover {
    background-color: $text-hover;
  }

  &:active {
    background-color: $buttons-primary-pressed;
  }
}
