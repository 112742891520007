@import "../../../assets/scss/helpers/variables";

.logo {
  margin-left: 12px;
  width: 132px;
  height: 51px;
  background: no-repeat center url(./assets/mainLogoBig.svg);
  @media (max-width: $md) {
    margin-left: 0;
    height: 40px;
    width: 40px;
    background: no-repeat center url(./assets/mainLogoSmall.svg);
  }
}
