@import "../../../../assets/scss/helpers/variables";

.container {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background: linear-gradient(to right, $line-primary 50%, transparent 0%),
        linear-gradient($line-primary 50%, transparent 0%), linear-gradient(to right, $line-primary 50%, transparent 0%),
        linear-gradient($line-primary 50%, transparent 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size:
        10px 1px,
        1px 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    position: relative;

    &Active {
        background: linear-gradient(to right, $buttons-primary-default 50%, transparent 0%),
            linear-gradient($buttons-primary-default 50%, transparent 0%),
            linear-gradient(to right, $buttons-primary-default 50%, transparent 0%),
            linear-gradient($buttons-primary-default 50%, transparent 0%);
        background-position: top, right, bottom, left;
        background-repeat: repeat-x, repeat-y;
        background-size:
            10px 1px,
            1px 10px;
        background-color: $background-grey;
    }

    &Error {
        background: transparent;
        border: 1px solid $alert;
    }

    &Disabled {
        opacity: 0.6;
        pointer-events: none;
        filter: grayscale(100%);
    }

    .area {
        pointer-events: none;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .title {
        display: block;
        margin: 20px auto 10px;
        font-weight: 400;
        font-size: 16px;
        color: $text-secondary-secondary;
        line-height: 1.44;

        .button {
            color: $buttons-primary-default;
        }
    }

    .info {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.44;
        color: $text-secondary-secondary;

        &Error {
            color: $alert;
        }

        .quantity {
            font-weight: 600;
            color: $buttons-primary-default;
        }

        .quantity {
            font-weight: 600;
            color: $buttons-primary-default;
        }
    }

    .error {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.44;
        color: $alert;
    }
}