@import "../../../assets/scss/helpers/variables";

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%;
  z-index: 3;
  transition: 0.5s;
  transform: translateY(0);

  &Hidden {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }

  .container {
    background-color: $white;
    border-radius: 5px;
    width: 1440px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 152px;
    flex-direction: row;
    box-shadow: 0 -2px 4px 0 rgb(14 56 100 / 10%);

    .text {
      max-width: 900px;
      font-size: 16px;
      color: $text-secondary-secondary;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}