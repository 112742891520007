@import "../../../assets/scss/helpers/mixins";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.hasItems {
        gap: 20px;
    }

    .filesColMd {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }

    .filesColSm {
        grid-template-columns: repeat(3, 1fr);
    }
}