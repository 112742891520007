@import '../../../assets/scss/helpers/variables';

.stepTitle {
    display: flex;
    gap: 12px;

    .stepCount {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $line-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .stepText {
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;

        span {
            font-weight: 700;
            padding-left: 4px;
        }
    }
}