@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.add {
  align-self: start;
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: $buttons-primary-default;
  transition: 0.35s;
  &Active {
    color: $buttons-primary-pressed;
  }
}

.editTitle {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: $buttons-primary-default;
  h4 {
    font-size: 16px;
    font-weight: 400;
    color: $gray-concrete;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .wrapper {
    color: $buttons-primary-default;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .link {
      @include link;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 350px;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
